import { object, string, number, date, InferType, boolean, array, lazy } from 'yup';

const campaignSchema =  object().shape({
  goal: string().required().oneOf(["OUTCOME_SALES" ]),
  name: string().required(),
  budget: string(),
  page: object().shape({
    picture: string().required(),
    name: string().required(),
    id: string().required(),
  }),
  adAccount:  object().shape({
    id: string().required(),
  }),
  product: object().shape({
    coverImage: string().required(),
    title: string().required(),
    id: string().required(),
  }),
  pixel: object().shape({
    is_unavailable: boolean(),
    name: string().required(),
    id: string(),
  }),
  budget: string().required(),
  countries: array().of(
    object().shape({
      key: string().required(),
      name: string().required(),
      type: string().required(),
      country_code: string().required(),
      country_name: string().required(),
      supports_region: boolean().required(),
      supports_city: boolean().required(),
    })
  ).min(1),
  conversion_goal: string().required().oneOf(["ADD_TO_CART", "ADD_TO_WISHLIST", "INITIATED_CHECKOUT", "PURCHASE" ]),
  adsets: array().of(
    object().shape({
      title: string().required(),
      adCopy: string().required(),
      mediaURL: string().required(),
      thumbnailUrl: string().required(),
    })
  ).min(1)
});

export default campaignSchema;
