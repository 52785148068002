import React from "react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import Icon from "./Icon";
import debounce from "lodash.debounce";
import { Label } from "flowbite-react/components/Label";


const MultiDropdown = ({
  label,
  isSearchable = true,
  placeholder = "",
  onChange,
  bindContext,
  withImage,
  debug = false,
  service,
  menuPlacement = "bottom",
  values: selectedValues,
  ...props
}) => {
  
  const handleChange = (selectedOption) => {
    if (selectedValues.some((value) => value.key === selectedOption.key)) {
      return;
    }
    if (bindContext) {
      const [{ setItem }, path] = bindContext;

      setItem(path, [...selectedValues, selectedOption]);
    }
  };

  const handleDelete = (value) => {
    if (bindContext) {
      const [{ setItem }, path] = bindContext;
      setItem(
        path,
        selectedValues.filter((item) => item.key !== value.key)
      );
    }
  };

  const SingleValue = ({ children, data, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <div className="flex gap-2 items-center w-full">
          <div className="w-6 rounded">
            {data.country_code ? (
              <img
                src={`https://flagsapi.com/${data.country_code}/flat/16.png`}
              />
            ) : (
              <Icon name="Image" color={"#CAD1DB"} />
            )}
          </div>
          {children}
        </div>
      </components.SingleValue>
    );
  };

  const Option = ({ children, data, ...props }) => {
    return (
      <components.Option {...props}>
        <div className="flex gap-2 items-center">
          <div className="w-6 rounded">
            {data.country_code ? (
              <img
                src={`https://flagsapi.com/${data.country_code}/flat/16.png`}
              />
            ) : (
              <Icon name="Image" color={"#CAD1DB"} />
            )}
          </div>
          {children}
        </div>
      </components.Option>
    );
  };

  const loadOptions = (inputValue, callback) => {
    service(inputValue).then((res) => {
      const { data, error } = res;
      if (error) {
        callback([]);
        return;
      }
      callback(data);
    });
    return;
  };
  // Debounce the loadOptions function
  const debouncedLoadOptions = debounce(loadOptions, 300);


  return (
    <div>
      {label && (
         <div className="mb-2 block">
         <Label htmlFor="" value={label} />
       </div>
      )}
      <AsyncSelect
        loadOptions={debouncedLoadOptions}
        className="basic-single"
        classNamePrefix="select"
        isSearchable={true}
        placeholder={placeholder}
        onChange={handleChange}
        components={{ SingleValue, Option }}
        getOptionValue={(option) => `${option.key}`}
        getOptionLabel={(option) => `${option["name"]}`}
        menuPlacement={menuPlacement}
        // onInputChange={console.log}
        value={null}
        // styles={colourStyles}
      />
      <div className="flex flex-wrap gap-2 my-3">
        {selectedValues.map((value, index) => {
          return (
            <div
              key={index}
              className="flex gap-2 items-center p-2 bg-blue-100 rounded border"
            >
              <img
                src={`https://flagsapi.com/${value.country_code}/flat/16.png`}
              />
              <span className="text-[14px] text-black-100">{value.name}</span>
              <div
                onClick={() => handleDelete(value)}
                className="cursor-pointer"
              >
                <Icon name="X" color="#F5432C" size="16" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiDropdown;
