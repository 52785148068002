import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Icon from "../Icon";
import Input from "../Input";
import { useTableContext } from "../../context/TableContext";
import { Tooltip } from "react-tooltip";
import toast from 'react-hot-toast';
import {
  imagyTextBodyTemplate,
  linkBodyTemplate,
  statusBodyTemplate,
} from "./Cells";
import { updateProductService, deleteProductService } from "../../utils/services"
import Modal from "../Modal";

const ProductsTable = ({ setProductState, productState }) => {
  const {
    setSelectedItem,
  } = useTableContext();

  const [toDelete, setToDelete] = useState(null);

  const handleDelete = async () => {
    try {
      const { error, data: success } = await deleteProductService(toDelete._id);
      if (error) {
        console.error("Error deleting product:", error);
        return;
      }
      if (success) {
        setProductState({
          ...productState,
          data: productState.data.filter((product) => product._id !== toDelete._id),
        });
      }
      setToDelete(null);
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex  gap-3 cursor-pointer">
        <div onClick={() => setSelectedItem(rowData)}>
          <Icon name="Pencil" size="16" />
        </div>
        <div onClick={() => setToDelete(rowData)}>
          <Icon name="Trash" size="16" color="red" />
        </div>
      </div>
    );
  };

  const EditableCostBodyTemplate = (rowData) => {
    const [isEditing, setIsEditing] = useState(false);
    let isAllgood = true;
    let text = "";
    let action = "";
    let variants = rowData.variants;

    if (variants.length === 1) {
      action = "edit";
      text = variants[0]?.price + " / ";;
      if (variants[0]?.cost) {
        text += variants[0]?.cost;
      } else {
        text += " - ";
        isAllgood = false;
      }
    } else {
      action = "select";
      text = "Multiple variants";
      isAllgood = variants.every((variant) => variant.cost);
    }

    const handleClick = () => {
      if (action === "select") {
        setSelectedItem(rowData);
      } else {
        setIsEditing(true);
      }
    };

    return (
      <div>
        <Tooltip id={"tooltip" + rowData.id} className="tooltip-content" opacity="1">
          <div className="flex flex-col gap-4">
            <h3>Missing one or more costs</h3>
          </div>
        </Tooltip>
        {!isEditing ? (
          <div
            className="flex items-center gap-2 justify-between cursor-pointer"
            onClick={handleClick}
          >
            <span className="font-bold">{text}</span>
            <div className="flex gap-2  items-center">
              {!isAllgood && <div
                className="font-medium justify-end flex text-right"
                data-tooltip-id={"tooltip" + rowData.id}
                data-tooltip-place="top"
                data-tooltip-variant="light"
                data-tooltip-position-strategy="fixed"
                ><Icon name="AlertTriangle" size="16" color="#FFA800" />
                
                </div>
              }
              <Icon name="Pencil" size="16" />
            </div>
          </div>
        ) : (
          <EditingCostBody rowData={rowData} setIsEditing={setIsEditing} />
        )}
      </div>
    );
  };

  const EditingCostBody = ({ rowData, setIsEditing }) => {
    const originalValue = rowData.variants[0]?.cost || "";
    const [newValue, setNewValue] = useState(originalValue);
    const [isValid, setIsValid] = useState(true);
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
      const isValidNumber = /^\d+(\.\d+)?$/.test(newValue);
      setIsValid(isValidNumber);
    }, [newValue]);

    const updateCost = async () => {
      if (isValid === false) return;
      try {
        setIsloading(true);
        const payload = {
          productIds: [rowData._id],
          idsPrice: [{ id: rowData.variants[0].id, cost: newValue }],
          publishers: [],
        };
        const { error } = await updateProductService(payload);
        if(error){

          toast.error("Error updating product");
          setIsloading(false);
          return
        }
        setProductState({ ...productState, data: productState.data.map(product => product.id === rowData.id ? { ...product, variants: [{...rowData.variants[0], cost:newValue}] } : product) });
        setIsEditing(false)
        toast.success("Product updated successfully");
      } catch (error) {
        toast.error("Error updating product");
      }
      
      setIsloading(false);
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        updateCost();
      } else if (e.key === "Escape") {
        setIsEditing(false);
      }
    };

    return (
      <div className="flex items-center gap-2 w-[170px]">
        <Input
          sizing="sm"
          disabled={isLoading}
          type="number"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <div className="flex gap-2 items-center cursor-pointer">
          <div onClick={updateCost}>
            <Icon
              name="Save"
              color={isValid ? "#5a5c73" : "#F5432C"}
              size="16"
            />
          </div>
          <div onClick={() => setIsEditing(false)}>
            <Icon name="X" color="#F5432C" size="16" />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="my-3 rounded mx-4 shadow">
        <DataTable
          value={productState?.data}
          loading={productState?.loading}
          stripedRows
          showGridlines
          paginator
          rows={10}
          tableStyle={{ minWidth: "50rem" }}
          // selection={selectedItems}
          // onSelectionChange={(e) => {
          //   setSelectedItems(e.value);
          // }}
          emptyMessage="No available data"
        >
          {/* <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column> */}
          <Column
            field="product"
            header="Product"
            body={(rowData) =>
              imagyTextBodyTemplate(rowData.title, rowData.coverImage)
            }
          />
          <Column
            field="store"
            header="Store"
            body={(rowData) =>
              linkBodyTemplate(rowData.storeName, rowData.adminLink)
            }
          />
          <Column 
            field="cost" 
            header="Price / Cost" 
            body={EditableCostBodyTemplate} 
          />
          <Column
            field="status"
            header="Status"
            body={(rowData) => statusBodyTemplate(rowData.status)}
          />

          <Column
            header="Actions"
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          />
        </DataTable>
      </div>
      <Modal 
        showModal={toDelete}
        setShowModal={() => setToDelete(null)}
        title="Delete Product"
        onClose={() => setToDelete(null)}
        actions={[
          { value: "Delete", type:"danger", onClick: handleDelete ,icon:{name:"Trash", color:"#F5432C"}}
        ]}
      >
        <div className="text-[#2b4d75] flex flex-col gap-3">
          <p>Are you sure you want to delete this product?</p>
          <p className="text-bold mx-4"> * {toDelete?.title}</p>
        </div>
      </Modal>
    </>
  );
};

export default ProductsTable;
