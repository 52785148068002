import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Icon from "../Icon";
import { useTableContext } from "../../context/TableContext";
import {
  imagyTextBodyTemplate,
  linkBodyTemplate,
  statusBodyTemplate,
} from "./Cells";

const SyncAdAccountsTable = ({ adAccountsState }) => {
  const {
    selectedItems,
    setSelectedItems
  } = useTableContext();


  return (
    <>
      <div className="my-3 rounded mx-4 shadow">
        <DataTable
          value={adAccountsState?.data}
          loading={adAccountsState?.loading}
          stripedRows
          showGridlines
          paginator
          rows={10}
          tableStyle={{ minWidth: "50rem" }}
          selection={selectedItems}
          onSelectionChange={(e) => {
            setSelectedItems(e.value);
          }}
          emptyMessage="No available data"
        >
          <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
          ></Column>
          <Column
            field="name"
            header="Name"
            body={(rowData) =>
              imagyTextBodyTemplate(rowData.name, rowData.image)
            }
          />
          <Column
              field="id"
              header="#ID"
          />
          <Column
              field="businessName"
              header="Business Name"
          />
          <Column
            field="status"
            header="Status"
            body={(rowData) => statusBodyTemplate(rowData.status)}
          />
        </DataTable>
      </div>
    </>
  );
};

export default SyncAdAccountsTable;
