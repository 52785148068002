import Icon from "./Icon";

const Card = ({ children, title, icon, info }) => {
  return (
    <div className="bg-white px-4 py-3 flex flex-col gap-4 rounded-lg shadow">
      {(icon || title) && (
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {icon ? (
              <div className={"pr-2"}>
                <Icon name={icon} size="18" color="#2b4d75" />
              </div>
            ) : undefined}
            {title ? (
              <label
                htmlFor=""
                className=" text-[18px]  font-medium"
              >
                {title}
              </label>
            ) : undefined}
          </div>
          {info && info}
        </div>
      )}

      {children}
    </div>
  );
};

export default Card;
