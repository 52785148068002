import React from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

import Page from "./Page";

const NotFound = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };
  return (
    <Page>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-[600px] ">
          <img src="/images/404-Error.png" alt="error"/>
        </div>
        <div className="flex flex-col gap-4">
          <Button
            className="w-[600px]"
            onClick={handleBack}
            value="Go to Dashboard"
            icon={{
              name: "Undo2",
            }}
          />
        </div>
      </div>
    </Page>
  );
};

export default NotFound;
