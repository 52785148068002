import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  Textarea,
  Dropdown,
  Icon,
  MultiDropdown,
} from "../../components";
import { HiSparkles  } from "react-icons/hi";
import { Spinner } from "flowbite-react/components/Spinner";

import FbPost from "../../components/previews/FbPost";
import { useCampaignContext } from "../../context/CampaignContext";
import { useSpringCarousel } from "react-spring-carousel";
import Button from "../../components/Button";
import { searchCountriesService } from "../../utils/services";
import FilePicker from "../../components/FilePicker";
import FileUpload from "../../components/flowbite/FileInput";
import { generateCreatives } from "../../utils/services";

const CreateCampaign = ({ setStep, step }) => {
  const newCampaignContext = useCampaignContext();
  const [loadingState, setLoadingState] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const {
    state: { settings },
    handleSubmit,
    loading,
    error,
    setItem
  } = newCampaignContext;

  const onFocus = (index) => {
    setFocusedIndex(index);
  };
  const handleBack = () => {
    setStep(step - 1);
  };

  const handleGenerateCreatives = async (index) => {
    if(loadingState[index]) return
    onFocus(index);
    setLoadingState((prev) => prev.map((item, i) => (i === index ? true : item)));
    const {
      data,
      error
    } = await generateCreatives(settings.product.id, settings.store.id);

    if(!error && data){
      setItem(`settings.adsets.${index}.adCopy`, data);
    }
    
    setLoadingState((prev) => prev.map((item, i) => (i === index ? false : item)));
  }

  useEffect(() => { 
    setLoadingState(Array(settings.adsets.length).fill(false));
  }, [settings.adsets]);

  return (
    <div className="flex gap-4 px-2">
      <div className="flex-1 flex flex-col gap-4 px-2 h-[calc(100vh-100px)] overflow-y-scroll overflow-x-hidden">
        <Card title={"Creative Medias"}>
          <FilePicker
            value={settings.adsets}
            bindContext={[newCampaignContext, "settings.adsets"]}
          />
        </Card>
        {settings?.adsets?.length > 0 ? (
          settings.adsets.map((adset, index) => {
            return (
              <Card title={`#${index + 1}  Creative Copy`} key={adset.fileId}>
                  <Input
                  label={"Title"}
                  placeholder="Ad title"
                  error={`${error.title ? error.title : ""}`}
                  value={adset.title}
                  bindContext={[
                    newCampaignContext,
                    `settings.adsets.${index}.title`,
                  ]}
                  onFocus={() => onFocus(index)}
                />
                
                <Textarea
                  label={"Ad copy"}
                  placeholder="Ad description"
                  rows="4"
                  value={adset.adCopy}
                  bindContext={[
                    newCampaignContext,
                    `settings.adsets.${index}.adCopy`,
                  ]}
                  onFocus={() => onFocus(index)}
                />
                <div className="flex justify-end">
                  <div className={`gradien hover:shadow px-4 py-2 rounded-full cursor-pointer transition ${loadingState[index] ? "opacity-70" : "opacity:100"}`}  onClick={() => handleGenerateCreatives(index)} >
                    <span className="text-white flex gap-3 items-center">
                      Generate with AI 
                      {loadingState[index] ?
                         <Spinner size="sm" color="info" />
                        :
                        <HiSparkles  color="white"/>
                      }
                    </span>
                  </div>
                </div>
                <FileUpload
                  label="Thumbnail"
                  value={adset.thumbnailUrl}
                  bindContext={[
                    newCampaignContext,
                    `settings.adsets.${index}.thumbnailUrl`,
                  ]}
                />
              </Card>
            );
          })
        ) : (
          <div className="flex items-center gap-2 bg-orange p-2 shadow rounded text-sm font-semibold text-white w-full">
            <Icon name={"AlertTriangle"} size={18} color={"white"} />
            Upload 1 or more media to create creatives
          </div>
        )}

        <Card title={"Setup"}>
          <div className="flex items-center gap-2">
            <div className="w-1/2">
              <Input
                label={`Daily Budget (${settings?.adAccount?.currency || " "})`}
                placeholder="Select daily budget"
                type="number"
                value={settings.budget}
                bindContext={[newCampaignContext, "settings.budget"]}
              />
            </div>
            <div className="w-1/2">
              <Dropdown
                label="Call to action"
                name="Call to action"
                value={settings.callToAction}
                options={[
                  { name: "Order Now", id: "ORDER_NOW" },
                  { name: "Get Offer", id: "GET_OFFER" },
                  { name: "Learn More", id: "LEARN_MORE" },
                  { name: "Sign Up", id: "SIGN_UP" },
                ]}
                debug={true}
                bindContext={[newCampaignContext, "settings.callToAction"]}
                isClearable={false}
              />
            </div>
          </div>
          <MultiDropdown
            label="Country Targeting"
            placeholder="Type to search countries"
            bindContext={[newCampaignContext, "settings.countries"]}
            values={settings.countries}
            service={searchCountriesService}
          />
        </Card>

        <div className="flex items-center justify-between w-full">
          <Button onClick={handleBack} type="secondary" value={"Back"} />
          <Button
            value={"Launch"}
            onClick={handleSubmit}
            loading={loading}
            icon={{ name: "Rocket" }}
          />
        </div>
      </div>
      <div className="w-[400px] overflow-hidden flex justify-center">
        <PostsCarousel
          adsets={settings.adsets}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
        />
      </div>
    </div>
  );
};

const PostsCarousel = ({ adsets, focusedIndex }) => {
  const { carouselFragment, slideToPrevItem, slideToNextItem, slideToItem } =
    useSpringCarousel({
      withLoop: true,
      items: adsets.length
        ? adsets.map((adset, index) => ({
            id: adset.fileId,
            renderItem: <FbPost key={index} adset={adset} />,
          }))
        : [
            {
              id: 0,
              renderItem: (
                <FbPost
                  adset={{
                    title: "Ad Title",
                    adCopy: "Ad Copy",
                  }}
                />
              ),
            },
          ],
    });

  useEffect(() => {
    if (focusedIndex !== null && focusedIndex < adsets.length) {
      slideToItem(focusedIndex);
    }
  }, [focusedIndex]);

  return (
    <div className="flex flex-col gap-2 mx-6 w-[380px] relative">
      <div className="">{carouselFragment}</div>
      <div className="flex items-center justify-center gap-4">
        <div className="cursor-pointer" onClick={slideToPrevItem}>
          <Icon name={"ChevronLeft"} size={28} />
        </div>
        <div className="cursor-pointer" onClick={slideToNextItem}>
          <Icon name={"ChevronRight"} size={28} />
        </div>
      </div>
    </div>
  );
};
export default CreateCampaign;

{
  /* 
                <div className="flex items-center gap-2 bg-blue-200 p-2 shadow rounded text-sm font-semibold text-black-100 w-full">
                  <Icon name={"Info"} size={18} color={"#697586"} />
                  Product image is used by default as cover if no media is uploaded.
                </div> */
}
