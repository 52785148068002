import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CampaignsContextProvider, useCampaignsContext } from "../../context/CampaignsContext";
import Page from "../Page";
import CampaignPreview from "../../components/previews/CampaignPreview";
import DashboardTable from "../../components/table/DashboardTable"
import { ads, campaignData, setAds } from "../../components/table/CampaignData";
// import { ads, campaignData, setAds } from "../../components/table/tableData";

const Campaigns = () => {
  const [tempData, setTempData] = useState([])
  const [selectedAdLevel, setSelectedAdLevel] = useState(0)

  useEffect(() => {
    if(selectedAdLevel === 0) {
      setTempData(campaignData)
    }
    else if(selectedAdLevel === 1) {
      setTempData(setAds)
    }
    else if(selectedAdLevel === 2) {
      setTempData(ads)
    } else {
      setTempData([])
    }
  },[selectedAdLevel])

  const navigate = useNavigate();
  const actions = [
    {
      name: "Create new",
      action: () => navigate("/campaigns/create"),
      type: "primary",
      icon: {
        name: "Rocket",
        color: "white",
      },
    },
  ];

  const emptyState = {
    actions,
    title: "Launch your first campaign",
    subtitle:
      "Easy steps to create a powerful campaign",
    image: "campaign.png",
  };


  
  return (
    <Page title="Campaigns Overviews" actions={actions}>
        <CampaignsContextProvider
          {...{
            emptyState,
            selectedAdLevel,
            setSelectedAdLevel
          }}
        >
          <DashboardTable  />
          {/* <CampaignPreview  fetchCampaigns={fetchCampaigns} /> */}
      </CampaignsContextProvider>
      

    </Page>
  );
};



export default Campaigns;
