import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import {
  fetchAdAccountsService,
  fetchStoresService,
  defaultFetchState,
  fetchProductsService,
} from "../utils/services";
import { SetItemReducer, SET_ITEM, SET_ALL } from "../reducers/SetItemReducer";
import { useAppContext } from "./Context";
import API from "../utils/api";
import { useNavigate } from "react-router";
import { useErrorContext } from "./ErrorContext";
import toast from "react-hot-toast";
import campaignSchema from "../validations/campaignValidation";

const campaignContext = createContext();

export const CampaignContextProvider = ({ children, data }) => {
  const { userData } = useAppContext();
  const defaultState = data || {
    goal: "",
    name: "",
    page: {},
    product: {},
    callToAction: { name: "Order Now", id: "ORDER_NOW" },
    customDomain: "",
    countries: [],
    conversion_goal: "",
    adsets: [],
  };

  const [state, dispatch] = useReducer(SetItemReducer, {});
  const [adAccountsState, setAddAccountState] = useState(defaultFetchState);
  const [pagesState, setPagesState] = useState(defaultFetchState);
  const [productsState, setProductsState] = useState(defaultFetchState);
  const [pixelsState, setPixelsState] = useState(defaultFetchState);
  const [storesState, setStoresState] = useState(defaultFetchState);
  const [loading, setLoading] = useState(false);
  const { setStatus } = useErrorContext();
  const [error, setError] = useState({});

  const setItem = (path, value) => dispatch({ type: SET_ITEM, path, value });
  const discard = () => {
    dispatch({
      type: SET_ITEM,
      path: "settings",
      value: defaultState,
    });
  };
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    let hasErrors = false;
    try {
      const results = await campaignSchema.validate(state.settings, {
        abortEarly: false,
      });
      console.log("results");
      console.log(results);
    } catch (err) {
      console.log("err");
      hasErrors = err?.inner.map(({ path, message }) => ({ path, message }));
      console.log(hasErrors);
      setError(hasErrors);
    }

    if (hasErrors) {
      toast.error("Please fill in all required fields");
      setLoading(false);
      return;
    }

    const toastId = toast.loading("Launching...");
    try {
      await API.post("/addCampaigns", state.settings);

      toast.dismiss(toastId);
      toast.success("Campaign launched successfully");
      navigate("/campaigns");
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Oops! Something went wrong");
    }

    setLoading(false);
  };

  useEffect(() => {
    dispatch({ type: SET_ALL, value: { settings: defaultState } });
  }, []);

  const fetchAdAccountsEffect = async () => {
    setStatus(true);
    setAddAccountState({ loading: true, data: [], error: null });
    const response = await fetchAdAccountsService();
    setAddAccountState(response);
    if (response.error?.response?.status === 500) {
      setStatus(false);
    }
  };

  const fetchPages = async () => {
    setPagesState({ loading: true, data: [], error: null });
    try {
      setStatus(true);
      const { data } = await API.get("/pages");
      setPagesState({
        loading: false,
        data: data.pages,
        error: null,
      });
    } catch (error) {
      console.log("🚀 ~ fetchPages ~ error:", error);
      setPagesState({
        loading: false,
        data: [],
        error: error,
      });
      if (error.response.status === 500) {
        setStatus(false);
      }
    }
  };

  const fetchProducts = async () => {
    setProductsState({ loading: true, data: [], error: null });
    try {
      setStatus(true);
      let response;
      const { data: products, error } = await fetchProductsService(
        userData.role
      );
      if (error) {
        throw error;
      }
      response = products.map(
        ({ id, title, variants, coverImage, link, storeName }) => ({
          id,
          title,
          variants,
          coverImage,
          link,
          storeName,
        })
      );

      setProductsState({
        loading: false,
        data: response,
        error: null,
      });
    } catch (error) {
      console.log(error);
      setProductsState({
        loading: false,
        data: [],
        error: error,
      });
    }
  };

  const fetchPixels = async () => {
    if (!state?.settings?.adAccount?.id) return;
    setPixelsState({ loading: true, data: [], error: null });
    try {
      setStatus(true);
      const { data } = await API.get(
        "/pixels/" + state?.settings?.adAccount?.id
      );
      setPixelsState({
        loading: false,
        data: data.pixels,
        error: null,
      });
    } catch (error) {
      console.log("🚀 ~ fetchPixels ~ error:", error);
      setPixelsState({
        loading: false,
        data: [],
        error: error,
      });
      if (error.response.status === 500) {
        setStatus(false);
      }
    }
  };

  useEffect(() => {
    fetchAdAccountsEffect();
    fetchProducts();
    fetchPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPixels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.settings?.adAccount?.id]);

  useEffect(() => {
    const fetchStoresEffect = async () => {
      setStoresState({ ...storesState, loading: true });
      setStoresState(await fetchStoresService());
    };
    fetchStoresEffect();
  }, []);

  return (
    <campaignContext.Provider
      value={{
        state,
        dispatch,
        setItem,
        discard,
        handleSubmit,
        error,
        adAccounts: adAccountsState,
        pages: pagesState,
        products: productsState,
        pixels: pixelsState,
        loading: loading,
        stores: storesState,
      }}
    >
      {Object.keys(state).length ? children : "loading"}
    </campaignContext.Provider>
  );
};

export const useCampaignContext = () => {
  return useContext(campaignContext);
};
