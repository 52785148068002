import { useTableContext } from "../../context/TableContext";
import Button from "../Button";
import { useAppContext } from "../../context/Context";
import TableData from "../table/TableData";

const TableCamp = () => {
  const { setAssignProducts } = useAppContext()
  const {
    paginatedList,
    tableConfiguration,
    selectedItems,
    selectable,
    actions,
    setSelectedItems,
    tableData,
    setAssigneAd
  } = useTableContext();

  const handleSelectAll = (e) => {
    // console.log(e.target.checked);
    setSelectedItems(e.target.checked ? tableData : []);
    setAssignProducts(e.target.checked ? tableData : 0)
    setAssigneAd(e.target.checked ? tableData : 0)
  };

  return (
    <table className="customTable table bg-white shadow w-full">
      <thead>
        <tr>
          {selectable && (
            <th className=" text-left py-[14px] px-4 ">
              <input
                type="checkbox"
                className="w-full h-4 m-[2px]"
                id="termsAndConditions"
                checked={selectedItems.length === tableData.length}
                onChange={handleSelectAll} //TODO: thinking of a better logic
              />
            </th>
          )}

          {selectedItems.length ? (
            <th colSpan={tableConfiguration.length}>
              <div className="flex items-center">
                <div className="mx-4">{selectedItems.length} Selected</div>
                {actions.map(({ action, name, type, icon }, index) => {
                  return (
                    <Button
                      key={index}
                      onClick={action}
                      value={name}
                      type={type}
                      icon={icon}
                    />
                  );
                })}
              </div>
            </th>
          ) : (
            tableConfiguration.map((cell, index) => {
              return (
                <th key={cell.id} className="text-left   py-[14px] px-4">
                  {cell.name}
                </th>
              );
            })
          )}
        </tr>
      </thead>
      <tbody>
        {paginatedList.length ? (
          paginatedList.map((t, i) => (
            <TableData key={t.id} data={t} />
          ))
        ) : (
          <div className="flex items-center justify-center h-14 text-[#CAD1DB]">
            No data found
          </div>
        )}
      </tbody>
    </table>
  );
};

export default TableCamp;
