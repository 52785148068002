import { useState } from "react";
import Button from "../components/Button";
import api from "../utils/api";
import HeroImg from "../components/HeroImg";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router";

const ForgotPasswordLink = () => {
   const navigate = useNavigate();

   const [email, setEmail] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState(null);

   const handleSubmit = async (e) => {
      e.preventDefault();

      if (!email) {
         setError("Email is required");
         return;
      }

      setIsLoading(true);

      try {
         const res = await api.post("/forgot-password-link", {
            email: email,
         });
         setEmail("");
         toast.success("Password reset link sent successfully");
         navigate(`/login`)
      } catch (error) {
         console.log(error.message);
         setError(error?.response.data?.message);
      }

      setIsLoading(false);
   }

   return (
      <div className="h-screen flex">
         <HeroImg />
         <div className="w-[40%] h-full bg-[#fff] flex-col flex justify-center items-center  ">
            <div className="h-full flex justify-center items-center flex-col">
               <div className="w-[400px] bg-[#fff] rounded-[5px] flex gap-3  px-[40px]  ">
                  <div className=" flex flex-col gap-5 flex-1">
                     <div className="flex flex-col gap-1 items-center">
                        <h1 className="text-[20px] font-medium text-center text-[#0a1218] leading-[52px]">
                           Forgot password
                        </h1>
                     </div>
                     <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                        <div className="flex flex-col gap-4">
                           <div className="flex flex-col gap-4">
                              <label
                                 htmlFor="fullname"
                                 className="text-[16px] font-medium leading-6 text-[#0a1218]"
                              >
                                 Email
                              </label>
                              <div className="w-full px-4 py-[14px] rounded-lg border bg-white border-[#CAD1DB]  focus-within:border-[#0D6EFD] ">
                                 <input
                                    className="bg-transparent text-[14px] text#080f18] text-base font-normal leading-6 outline-none w-[90%]"
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="flex flex-col gap-3">
                           <Button
                              disabled={isLoading}
                              value={isLoading ? "Loading..." : "Submit"}
                              className="w-full font-medium text-[16px] leading-6 h-14 "
                           />
                        </div>
                        <p className="text-red-700">{error}</p>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
};

export default ForgotPasswordLink;