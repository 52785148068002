import React from "react";
import Icon from "./Icon";
import { Spinner } from "flowbite-react/components/Spinner";

const Button = ({
  type = "primary",
  onClick,
  icon,
  value,
  disabled,
  className="",
  loading,
}) => {
  const handleClick = () => {
    if (!disabled && !loading && onClick) {
      onClick();
    }
  };
  return (
    <button
      className={`
            flex rounded-lg gap-2 px-3 py-2 transition justify-center items-center ${className} 
            ${
              type === "primary" 
                ? " bg-blue text-[#fff]  "
                : ""
            }
            ${
              type === "secondary"
                ? "  text-blue  "
                : ""
            }
            ${
              type === "danger" 
                ? "  text-[#F5432C] "
                : ""
            }
            ${
              disabled || loading
                ? " opacity-50 cursor-not-allowed	"
                : " cursor-pointer"
            }
        `}
      onClick={handleClick}
    >
      {icon && !loading && <Icon name={icon.name} color={icon.color} />}
        {loading && <Spinner aria-label="Default status example" />}
      <div className="">
        {value}
        </div>
    </button>
  );
};

export default Button;
