import React from "react";

const HeroImg = () => {
  return (
    <div className="gradien  justify-center items-center h-screen w-[60%] lg:flex hidden ">
      <div className="flex h-screen flex-col   ">
        <div className=" flex flex-1 justify-center items-center gap-4 flex-col">
          <div className="h-20">
            <img src="/images/logos/logo_white_long.png" alt="hero" className="rounded h-20" />
          </div>
          <div className="w-[70%] text-center color-white relative">
            <div className="glassy rounded p-4 m-4  ">
              <img src="/images/preview.png" alt="hero" className="rounded" />
            </div>
            <img
              src="/images/preview-campaigns.png"
              alt="hero"
              className="rounded shadow absolute bottom-20"
            />
          </div>
          <div className="w-[60%] text-center text-white">
            <div>
              {" "}
              AdClair streamlines your ad attribution so you can focus on
              optimizing and scaling your ads profitably.
            </div>
          </div>
        </div>
        <div className="flex  justify-center py-10">
          <div className="h-20">
            <img
              src="/images/Meta-Business-PartnerWhite.png"
              alt="logo"
              className="h-20"
            />
          </div>
          <div className="h-20">
            <img
              src="/images/shopify-partner-white-MIN.png"
              alt="logo"
              className="h-20"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImg;
