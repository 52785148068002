import { useEffect } from "react";
import api from "../utils/api";
import { useAppContext } from "../context/Context";

const useUserPlan = () => {

   const { setUserPlan, setSubscriptionLoading } = useAppContext();

   const getUserPlan = async () => {
      setSubscriptionLoading(true);
      try {
         const res = await api.get("/getUserPlan");

         setUserPlan(res.data.userPlan);
      } catch (error) {
         console.log(error);
      }
      setSubscriptionLoading(false);
   }

   useEffect(() => {
      getUserPlan();
   }, [])
};

export default useUserPlan;
