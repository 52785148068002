import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Campaigns from "./pages/Campaigns/List";
import Campaign from "./pages/Campaigns";
import NotFound from "./pages/NotFound";
import SharedLayout from "./pages/dashboard/SharedLayout";
import Products from "./pages/Products";
import Ad from "./pages/Ad";
import Publishers from "./pages/Publishers";
import Invitation from "./pages/Invitation";
import SuperAdmin from "./pages/SuperAdmin";
import Invitations from "./pages/Invitations";
import Privacy from "./pages/Privacy";
import PrivacyV2 from "./pages/PrivacyV2";
import "./App.css";
import axios from "axios";
import IfLoggedIn from "./pages/IfLoggedIn";
import { useEffect, useState } from "react";
import { ErrorPage } from "./pages/Error";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import ForgotPasswordLink from "./pages/ForgotPasswordLink";
import ForgotPassword from "./pages/ForgotPassword";
import PayPalSubscription from "./pages/PayPalSubscription";
import Subscribed from "./layouts/Subscribed";
import Unsubscribed from "./layouts/Unsubscribed";
axios.defaults.baseURL = "http://localhost:8001/api";

function App() {

  return (
    <BrowserRouter>
      <Routes>
          <Route element={<Unsubscribed />}>
            <Route path="/subscription" element={<PayPalSubscription />} />
          </Route>
        <Route path="/" element={<PrivateRoute />} >
          <Route element={<Subscribed />}>
            <Route index element={<Home />} />
            <Route exact path="campaigns" element={<Campaigns />} />
            <Route path="campaigns/create" element={<Campaign />} />
            <Route path="products" element={<Products />} />
            <Route path="ad" element={<Ad />} />
            <Route path="publishers" element={<Publishers />} />
            <Route path="invitations" element={<Invitations />} />
            <Route path="adminpanel" element={<SuperAdmin />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route element={<IfLoggedIn />}>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/invitation/:id" element={<Invitation />} />
          <Route path="/signup/:id" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPasswordLink />} />
          <Route path="/forgot-password/:token" element={<ForgotPassword />} />
        </Route>
        <Route path="/privacy-policy" element={<PrivacyV2 />} />
        <Route path="/privacy-policy-2024" element={<Privacy />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const PrivateRoute = () => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");

    const checkAndRefreshToken = async () => {
      if (!authToken && refreshToken) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API}/get-auth-token`, { refreshToken });
          const { token } = response.data.data;
          localStorage.setItem("authToken", token);
          setAuthToken(token);
        } catch (error) {
          console.error("Token refresh failed:", error);
          localStorage.clear();
        }
      }
      setLoading(false);
    };

    checkAndRefreshToken();
  }, [authToken]);

  if (loading) {
    return null;
  }

  return authToken ? <SharedLayout /> : <Navigate to="/login" />;
};

// const PrivateRoute = () => {
//   const localStorageToken = localStorage.getItem("authToken");
//   return localStorageToken ? <SharedLayout /> : <Navigate to="/login" />;
// };

export default App;
