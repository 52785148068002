import React, { useCallback } from "react";
import Icon from "../Icon"

const FilesListItem = ({ name, id, onClear, index }) => {
  const handleClear = () => {
    onClear(id);
  }

  return (
    <li className="flex justify-between rounded  items-center  px-2 py-2 bg-gray-100 text-black font-bold text-[14px] hover:shadow transition">
      <div className="flex items-center gap-2">
        <span className="text-[#697586]">
          #{index}
        </span>
        <Icon name="File" color="#697586" size={16}/>
        <span >
          {name}
        </span>

      </div>
        <span
          role="button"
          aria-label="remove file"
          onClick={handleClear}
        >
          <Icon name="X" color="#F5432C" size={16}/>
        </span>
    </li>
  );
};

const FilesList = ({ files, onClear, uploadComplete }) => {
  return (
    <ul className="flex flex-col gap-2">
      {files.map(({ fileName, fileId }, index) => (
        <FilesListItem
          index={index+1}
          name={fileName}
          key={fileId}
          id={fileId}
          onClear={onClear}
          uploadComplete={uploadComplete}
        />
      ))}
    </ul>
  );
};

export { FilesList };
