import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { handleLogin } from "../../utils/helper";
import Api from "../../utils/api";

import SyncAdAccountsTable from "../table/SyncAdAccountsTable"
import { TableContextProvider } from "../../context/TableContext";
import { useTableContext } from "../../context/TableContext";
import { useErrorContext } from "../../context/ErrorContext";
import { defaultFetchState } from "../../utils/services";

const LinkAccountModal = ({ setShowModal, showModal, fetchAdAccounts }) => {
  const [account, setAccount] = useState(null);
  const [adAccountsState, setAdAccountsState] = useState({...defaultFetchState, data:null});


  const saveAccount = async ({ accessToken, userID }) => {
    try {
      setAdAccountsState({
        ...adAccountsState,
       loading: true,
      });
      const { data: adAccountsData } = await Api.post("/account", {
        accountUserId: userID,
        accessToken: accessToken,
        status: "active",
      });
      setAdAccountsState({
        ...adAccountsState,
        data: adAccountsData,
        loading: false,
      });
    } catch (e) {
      setAdAccountsState({
        data: null,
        loading: false,
        error: e,
      });
      
    }
  };
  const loginCallback = (response) => {
    setAccount(response);
    if (response.status === "connected") {
      saveAccount(response.authResponse);
    }
  };

  const fbLoginBtn = (
    <button onClick={ () => handleLogin(loginCallback) } className="fb-button-main-element" aria-label="Continuer avec Facebook" ><span className="fb_button_label_element fb_button_label"><svg viewBox="0 0 213 213" preserveAspectRatio="xMinYMin" className="fb_button_svg_logo login_fb_logo single_button_svg_logo"><path d="M90,212v-75h-27v-31h27v-25q0,-40 40,-40q15,0 24,2v26h-14q-16,0 -16,16v21h30l-5,31h-27v75a106 106,0,1,0,-32 0" className="f_logo_circle" fill="white"></path><path d="M90,212v-75h-27v-31h27v-25q0,-40 40,-40q15,0 24,2v26h-14q-16,0 -16,16v21h30l-5,31h-27v75a106 106,1,0,1,-32 0" className="f_logo_f" fill="white"></path></svg><span>Continue with Facebook</span></span></button>
  )

  const accountActions = [
    {
      value: "Privacy policy",
      onClick: () =>  window.open("/privacy-policy", "_blank"),
      type: "secondary",
    },
    {
      element: fbLoginBtn
    },
  ];

  useEffect(() => {
    if (window.FB) {
      window.FB.getLoginStatus(function (response) {
        console.log(response);
        const {  status } = response;
        if (status === "connected") {
          setAccount(response);
        } else {
          setAccount(response);
        }
      });
    }
  }, []);

  const onClose = () => {
    setShowModal(false)
  }
  return (
    <>
      {!adAccountsState.data ? (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          title={ "Link business account"}
          actions={ accountActions}
          onClose={onClose}
        >
          <div className="w-[500px] text-[#2b4d75]" >
            <p className="font-medium">
              You will be redirected to Facebook to connect your business
              account.
            </p>
            <p className="font-medium  text-[12px]">
              Make sure to select the business accounts that you want to use.
            </p>
          </div>
        </Modal>
      ) : (
        <TableContextProvider
          {...{
            selectable: true
          }}
        >
          <AdAccountsList {...{ showModal, onClose, setShowModal, account, fetchAdAccounts, adAccountsState }} />
        </TableContextProvider>
      )}
    </>
  );
};
export default LinkAccountModal;

const AdAccountsList = ({ showModal, onClose, setShowModal, account , fetchAdAccounts, adAccountsState}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedItems } = useTableContext();
    const { setStatus} = useErrorContext()

  const handleSave = async () => {
    try {
      setStatus(true)
      setIsLoading(true);
      await Api.post(`/adaccount`, {
        adAccounts: selectedItems,
        accountUserId: account.authResponse.userID,
      });
      fetchAdAccounts()
      setIsLoading(false);
      setShowModal(false);
    } catch (e) {
      setIsLoading(false);
      console.log("Error!!");
      if(e.response.status === 500) {
        setStatus(false)
      }
    }
  };
  const adAccountActions = [
    {
      value: "Link",
      onClick: handleSave,
      loading: isLoading,
      icon: {
        name: "Link",
        color: "white",
      },
    },
  ];
  
  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={"Select Ad Accounts"}
        actions={adAccountActions}
        onClose={onClose}
      >
        <div className="w-[1000px]">
          <SyncAdAccountsTable
            adAccountsState={adAccountsState}
          />
        </div>
      </Modal>
    </>
  );
};
