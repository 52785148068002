import React, { useState, useEffect } from "react";
import Page from "../Page";
import { useNavigate } from "react-router";
import {
  Card,
  Input,
  Textarea,
  Dropdown,
  Icon,
  VideoInput,
  MultiDropdown,
} from "../../components";
import FbPost from "../../components/previews/FbPost";
import { useCampaignContext } from "../../context/CampaignContext";
import Button from "../../components/Button";
import { searchCountriesService } from "../../utils/services";
import { TbWorldWww } from "react-icons/tb";
import Details from "./Details";

const Goal = ({ step, setStep }) => {
  const newCampaignContext = useCampaignContext();
  const navigate = useNavigate();
  const {
    state: { settings },
    setItem,
  } = newCampaignContext;
  const [selectedGoal, setSelectedGoal] = useState(settings.goal);

  const handleSelect = (goal) => {
    if(goal !== "OUTCOME_SALES") return;
    setItem("settings.goal", goal)
    setSelectedGoal(goal);
  };

  const handleNext = () => {
    setStep(step + 1)
  }

  return (
    <div className="flex gap-4 flex-col items-center ">
      <div className="text-xl font-bold mb-4" >What is your advertising goal?</div>
      <Option
        goal = "OUTCOME_SALES"
        selectedGoal={selectedGoal}
        icon={"ShoppingCart"}
        title="Online Sales"
        indicator={"AdPixel setup required"}
        subtitle="Increase your e-commerce sales by driving targeted traffic to your online store"
        onClick={() => handleSelect("OUTCOME_SALES")}
      />
      <Option
        goal = "OUTCOME_LEADS"
        selectedGoal={selectedGoal}
        locked
        icon={"Filter"}
        title="Online Leads"
        indicator={"AdPixel setup required"}
        subtitle="Capture potential customer information to grow your client base and boost conversions."
        onClick={() => handleSelect("OUTCOME_LEADS")}
      />
      <Option
        goal = "OUTCOME_AWARENESS"
        selectedGoal={selectedGoal}
        locked
        icon={"Eye"}
        title="Brand Awareness"
        subtitle="Enhance your brand's visibility and reputation to build long-term customer loyalty."
        onClick={() => handleSelect("OUTCOME_AWARENESS")}
      />
      <Option
        goal = "OUTCOME_TRAFFIC"
        selectedGoal={selectedGoal}
        locked
        icon={"MousePointerClick"}
        title="Website Traffic"
        subtitle="Drive more visitors to your website to boost online visibility & engagement."
        onClick={() => handleSelect("OUTCOME_TRAFFIC")}
      />
          <Button
              onClick={handleNext}
              type="primary"
              disabled={!selectedGoal}
              className="w-full"
              value={ "Next"}
          >
              Next
          </Button>
    </div>
  );
};

const Option = ({ icon, title, indicator, subtitle, onClick, locked, goal, selectedGoal }) => {
  return (
    <div
    onClick={ onClick}
      className={`${
            locked
            ? "bg-[#f9fafb] opacity-50 cursor-not-allowed	"
            : " cursor-pointer hover:border-blue hover:shadow"
        } 
        ${selectedGoal === goal ? "border-blue shadow bg-blue-200" : "bg-white"}
      
      flex  flex-col gap-4 p-6 border-2  transition rounded-lg w-full`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Icon name={icon} size={32} color={"#697586"} />
          <div className="ml-4">
            <h2 className="text-lg font-bold">{title}</h2>
          </div>
        </div>
        {indicator && (
          <div className="flex items-center gap-2 bg-blue-100 p-2 rounded text-sm font-bold text-black-100">
            <Icon name={"Info"} size={18} color={"#697586"} />
            {indicator}
          </div>
        )}
      </div>
      <div className="text-md font-semibold text-black-100">{subtitle}</div>
    </div>
  );
};


export default Goal;
