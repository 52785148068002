import React, { useState} from "react";

import { ReactComponent as Share } from "../../svgs/share.svg";
import { ReactComponent as Comment } from "../../svgs/comment.svg";
import { ReactComponent as Like } from "../../svgs/like.svg";
import { useCampaignContext } from "../../context/CampaignContext";
import Icon from "../Icon";
// import "../node_modules/video-react/dist/video-react.css";
import { Player } from "video-react";
import ReactPlayer from 'react-player';

const FbPost = ({
  adset
}) => {
  const newCampaignContext = useCampaignContext();
  const {
    state: {
      settings: { page, product, callToAction, customDomain },
    },
  } = newCampaignContext;

  const { name, picture } = page;
  const { coverImage } = product || {};

  const {
    adCopy ,
    title ,
    mediaURL ,
    thumbnailUrl
  } = adset
  console.log(adCopy)

  return (
    <div className="bg-white shadow-lg rounded-2xl w-full ">
  

      <div className="flex flex-col gap-2 p-4">
        <div className="flex gap-4">
          <div className="w-14 h-14 rounded-full ">
            <img
              className="rounded-full"
              src={
                picture || "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"
                // `https://picsum.photos/200?random=${Math.random() * 100}`
              }
              alt="not-found"
            />
          </div>
          <div className="flex-1 ">
            <div className="font-bold text-[#131d27] text-[0.75rem]">
              {name || "Business Name"}
            </div>
            <div className="font-medium text-[#5d778d] text-[0.75rem]">
              Sponsored
            </div>
          </div>
          <div>
            <Icon name="MoreHorizontal" />
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: adCopy || "Ad Copy..",
          }}
        className="font-medium text-[#131d27] text-[0.75rem]"
        />
      </div>
      <div>
        <div className="aspect-square	 ">
          {
            mediaURL ? <ReactPlayer 
              url={mediaURL} 
              light={thumbnailUrl || false}
              controls={true} 
              width="100%" 
              height="100%" 
            /> : 
            <img
              className="w-full aspect-square object-cover		"
              src={
                coverImage ||  "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"
                // `https://picsum.photos/600?random=${Math.random() * 100}`
              }
              alt="not-found"
            />
          }
          
        </div>
        <div className="bg-[#e1e8ef]  p-4 flex items-center">
          <div className="flex flex-col flex-1">
            <div className="font-medium text-[#5d778d] text-[0.75rem]">
              {customDomain || "domain"}
            </div>
            <div className="font-bold text-[#131d27] text-[0.75rem]">
              {title || "Title.."}
            </div>
          </div>
          <div>
            <p className="rounded-full py-1 px-2  m-2 border-2 font-medium cursor-pointer border-[#0188ff] text-[#0188ff]">
              {callToAction.name}
            </p>
          </div>
        </div>
      </div>
      <div className="text-[#5c6067] flex justify-between items-center px-4 py-3">
        <div className=" flex gap-2 items-center">
          <div>
            <Like />
          </div>
          <div>Like</div>
        </div>
        <div className=" flex gap-2 items-center">
          <div>
            <Comment />
          </div>
          <div>Comment</div>
        </div>
        <div className=" flex gap-2 items-center">
          <div>
            <Share />
          </div>
          <div>Share</div>
        </div>
      </div>
    </div>
  );
};

export default FbPost;
