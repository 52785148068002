import React from "react";
import Select, {components} from "react-select";
import Icon from "./Icon"
import { Label } from "flowbite-react/components/Label";

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  singleValue: (styles, { data }) => ({ ...styles, ...dot("blue") }),
};


const Dropdown = ({
  label,
  loading,
  isClearable = true,
  isSearchable = true,
  name,
  placeholder = "",
  value,
  error,
  onChange,
  bindContext,
  options = [],
  optionLabel,
  withImage,
  debug = false,
  indicator= "",
  ...props
}) => {
  let selectedOption =   options.find((option) =>{
    return  typeof value === "string" ?  option.id === value : option.id === value?.id
  }) || null;

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    if (bindContext) {
      const [{ setItem }, path] = bindContext;

      setItem(path, value);
    }
  };

  
  const SingleValue  = ({ children,data, ...props }) =>{ 
    return (
      <components.SingleValue  {...props}>
        <div className="flex gap-2 items-center w-full">
          {
            withImage &&data && (
              <div className="w-6 rounded">
                {data[withImage] ? 
                  <img src={data[withImage]}  className="w-6 rounded"/>
                    :
                  <Icon name="Image" color={"#CAD1DB"}  />
                }
              </div>
  
            )
          } 
        {children}
        </div> 
         
      </components.SingleValue >
    )
  };
  
const Option = ({ children, data, ...props }) =>{ 
  return (
    <components.Option {...props}>
      <div className="flex gap-2 items-center">
        {
          withImage && (
            <div className="w-6 rounded">
              {data[withImage] ? 
                <img src={data[withImage]}  className="w-6 rounded"/>
                  :
                <Icon name="Image" color={"#CAD1DB"}  />
              }
            </div>

          )
        } 
        {children}
      </div>
    </components.Option>
  )
};

  return (
    <div className={`gap-1 ${error && selectedOption === null? 'error-div': ""}`}>
      {label && (
         <div className="mb-2 block">
         <Label htmlFor="" value={label} />
       </div>
      )}
      <Select
        isLoading={loading}
        className="basic-single"
        classNamePrefix="select"
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        placeholder={placeholder}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        components={{ SingleValue , Option }}
        getOptionValue={option =>`${option.id}`}
        getOptionLabel = {option =>`${option[optionLabel || "name"]}`}
        // styles={colourStyles}
      />
      {
        indicator ? indicator : undefined
      }
       {error && selectedOption === null && (
          <div className="mx-2 font-small text-[12px] text-[#F5432C]">
            {error}
          </div>
        )}
    </div>
  );
};

export default Dropdown;
