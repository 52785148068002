import React from "react";
import Pagination from "rc-pagination";
import { ChevronRight, ChevronLeft } from "lucide-react";
import { useTableContext } from "../context/TableContext";

const PaginationComp = () => {
  const {
    countPerPage,
    goToPage,
    currentPage,
    filtredData: fullList,
  } = useTableContext();

  return (
    <div className="flex justify-end text-[15px] text-[#343436] font-medium px-6 py-4">
      <div
        onClick={() => {
          if (currentPage > 1) {
            goToPage(currentPage - 1);
          } else {
            goToPage(Math.ceil(fullList.length / countPerPage));
          }
        }}
        className="mx-2 text-[#343436] cursor-pointer hover:bg-[#f4f4f5] rounded p-2 transition flex items-center gap-2"
      >
       <ChevronLeft  size={16} />  Previous
      </div>
      <Pagination
        className="flex gap-4 items-center cursor-pointer pagination"
        pageSize={countPerPage}
        onChange={goToPage}
        current={currentPage}
        total={fullList.length}
        showLessItems
        itemRender={(current, type, element) => {
          if (type === "page") {
            const isActive = current === currentPage;
            return (
              <span
                className={`${
                  isActive
                    ? "border-[#63657E] shadow bg-white "
                    : "border-none border-0 hover:bg-[#f4f4f5]  "
                } custom-pagination-item transition  rounded `}
              >
                {current}
              </span>
            );
          }
          return element;
        }}
      />
      <div
        onClick={() => {
          if (currentPage < Math.ceil(fullList.length / countPerPage)) {
            goToPage(currentPage + 1)
          } else {
            goToPage(1);
          }
        }}
        className="mx-2 text-[#343436] cursor-pointer hover:bg-[#f4f4f5] rounded p-2 transition flex items-center gap-2"
      >
       Next <ChevronRight size={16} /> 
      </div>
    </div>
  );
};

export default PaginationComp;
