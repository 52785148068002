import React, { useState, useEffect } from "react";
import { Card, Input,  Dropdown, Icon} from "../../components";
import { useCampaignContext } from "../../context/CampaignContext";
import Button from "../../components/Button";
import { TbWorldWww } from "react-icons/tb";

const Connect = ({ setStep, step }) => {
  const newCampaignContext = useCampaignContext();
  const [customStore, setCustomStore] = useState(null)
  const [isProductHasCost, setIsProductHasCost] = useState(null)
  const {
    state: { settings },
    adAccounts,
    pages,
    products,
    pixels,
    stores,
    error,
    setItem
  } = newCampaignContext;

  const isConnectValid = settings.adAccount && settings.page && settings.product && settings.pixel;
  const isDetailsValid = settings.name;

  // get domain name of the store
  useEffect(() => {
    const _product = settings?.product
    if (_product?.id) {
      const store = stores.data.find(store => store.shopName === _product.storeName)
      const customDomain = store?.customDomain || _product.storeName
      const link = _product.link.replace(_product.storeName, customDomain)
      setItem("settings.customDomain", customDomain)
      setItem("settings.store", store)
      setCustomStore(link || "This store dosnt have a custome domain")
    } else {
      setCustomStore("Select a product")
    }
  }, [settings.product])

  // get variants cost flags
  useEffect(() => {
    if (settings?.product?.id) {
      const _product = settings?.product
      let isAllgood = true;
      let variants = _product.variants;
  
      if (variants.length === 1) {
        if (!variants[0]?.cost)   {
          isAllgood = false;
        }
      } else {
        isAllgood = variants.every((variant) => variant.cost);
      }
      setIsProductHasCost(isAllgood)
    } else {
      setIsProductHasCost(null)
    }
  }, [settings.product])

  const handleNext = () => {
    setStep(step + 1)
  }
  
  const handleBack = () => {
    setStep(step - 1)
  }


  return (
      <div className="flex gap-4 flex-col  px-2">
        <div className="flex-1 flex flex-col  gap-4  ">
          <Card
            title={"Details"}
            info={
              <Icon
                name={isDetailsValid ? "Check" : "CircleDashed"}
                color={isDetailsValid? "#34B86D" : "#CAD1DB"}
                size="20"
              />
            }
          >
            <Input
              label={"Name"}
              value={settings.name}
              error={`${error.name? error.name: ""}`}
              placeholder="Campaign name"
              bindContext={[newCampaignContext, "settings.name"]}
            />
          </Card>
          <Card 
            title={"Connect"}
            info={
              <Icon
                name={isConnectValid ? "Check" : "CircleDashed"}
                color={isConnectValid ? "#34B86D" : "#CAD1DB"}
                size="20"
              />
            }  
          >
            <Dropdown
              label="Ad Account"
              isLoading={adAccounts.loading}
              name="Ad account"
              error={`${error.account? error.account: ""}`}
              placeholder="Select ad account"
              value={settings.adAccount}
              options={adAccounts.data}
              bindContext={[newCampaignContext, "settings.adAccount"]}
              withImage={"image"}
            />
            <Dropdown
              label="Product"
              isLoading={products.loading}
              name="product"
              placeholder="Select product"
              value={settings.product}
              error={`${error.product? error.product: ""}`}
              options={products.data}
              bindContext={[newCampaignContext, "settings.product"]}
              optionLabel ="title"
              withImage="coverImage"
            />
            {isProductHasCost === false && (
                <div className="flex items-center gap-2 bg-orange p-2 shadow rounded text-sm font-semibold text-white w-full">
                <Icon name={"AlertTriangle"} size={18} color={"white"} />
                This product has missing one or more costs.
              </div>
              )
              }
            <Input
              label={"Product Link"}
              disabled={true}
              value={settings.customStore || customStore}
              icon={TbWorldWww }
            />

            <Dropdown
              label="Page"
              isLoading={pages.loading}
              name="page"
              placeholder="Select page"
              error={`${error.page? error.page: ""}`}
              value={settings.page}
              options={pages.data}
              bindContext={[newCampaignContext, "settings.page"]}
              withImage={"picture"}
            />

            <Dropdown
              label="Pixel"
              isLoading={pixels.loading}
              name="pixcel"
              error={`${error.pixel? error.pixel: ""}`}
              placeholder="Select pixel"
              value={settings.pixel}
              options={pixels.data}
              bindContext={[newCampaignContext, "settings.pixel"]}
            /> 
          </Card>
        </div>
        
        <div className="flex items-center justify-between w-full">
            <Button
                onClick={handleBack}
                type="secondary"
                value={ "Back"}
            />
            <Button
                onClick={handleNext}
                type="primary"
                disabled={!isDetailsValid || !isConnectValid}
                value={ "Next"}
            />
        </div>
      </div>
  );
};

export default Connect;
