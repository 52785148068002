import React from "react";
import { CampaignContextProvider } from "../../context/CampaignContext";
import Create from "./Create";

const Campaign = () => {

    return (
      <CampaignContextProvider >
        <Create />
      </CampaignContextProvider>
    )
}

export default Campaign