import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAppContext } from "../context/Context";

const Subscribed = () => {
   const { userPlan } = useAppContext();

   return userPlan ? <Outlet /> : <Navigate to="/subscription" />;
};

export default Subscribed;
