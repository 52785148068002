import { createContext, useContext, useState, useEffect } from "react";
import { addDays } from "date-fns";
import { useErrorContext } from "./ErrorContext";
import {
  fetchAdAccountsService,
  fetchCampaignsService,
  switchCampaignStatus,
} from "../utils/services";
import { formatDateToYYYYMMDD } from "../utils/helper";
const campaignsContext = createContext();

export const CampaignsContextProvider = ({
  children,
  data,
  selectable,
  selectedAdLevel,
  setSelectedAdLevel,
  actions = [],
}) => {
  const { setStatus } = useErrorContext();
  const defaultFetchState = { loading: false, data: [], error: null };

  const [adAccountsState, setAddAccountState] = useState(defaultFetchState);
  const [campaignsState, setCampaignsState] = useState(defaultFetchState);
  const [ordersState, setOrdersState] = useState(defaultFetchState);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [tableData, setTableData] = useState(data);
  const [selectedAdAccount, setSelectedAdAccount] = useState("");
  const [ filters,setFilters ] = useState({})
  const [campaignData, setCampaignData] = useState(null); //for campaign
  const [selectedCampaign, setSelectedCampaign] = useState({
    campaigns: [],
    adSets: [],
    ads: [],
  });
  const [selectedRange, setSelectedRange] = useState([
    {
      endDate: new Date(),
      startDate: addDays(new Date(), -30),
      key: "selection",
    },
  ]);
  const handleSelectAll = () => {
    //TODO: handle select all (only filtred data) |  thinking of a better logic
    if (!selectedItems.length) {
      setSelectedItems(tableData);
    } else {
      setSelectedItems([]);
    }
  };

  const selectItem = (targetItem) => {};

  const fetchAdAccountsEffect = async () => {
    setStatus(true);
    setAddAccountState({ loading: true, data: [], error: null });
    const response = await fetchAdAccountsService();
    setAddAccountState(response);
    if (response.data) {
      setSelectedAdAccount(response.data[0]);
    }

    if (response.error?.response?.status === 500) {
      setStatus(false);
    }
  };

  const onSwitch = async (campaignId, status) => {
    try {
      setStatus(true)
      const { data: updatedCampaign, error: updatingError } = await switchCampaignStatus(selectedAdAccount?.id, campaignId,status ? "PAUSED" : "ACTIVE" )
      if(updatingError){
        console.log("error", updatingError)
        return 
      }
      const tmpCamp = [...campaignsState.data];
      const indexOfUpdated = tmpCamp.findIndex(camp => camp.id === campaignId);
      tmpCamp[indexOfUpdated].status = updatedCampaign.status
      setCampaignsState(prevState => ({ ...prevState, data: tmpCamp }));
    } catch (error) {
      console.log(error.message);
      if(error.response.status === 500) {
        setStatus(false)
      }
    }
  };

  const onAdClick = () => {
    
  }

  //set the selecteditems based on the selectedAdLevel
  useEffect(() => {
    if (selectedAdLevel === 0) {
      setSelectedItems(selectedCampaign.campaigns);
    } else if (selectedAdLevel === 1) {
      setSelectedItems(selectedCampaign.adSets);
    } else if (selectedAdLevel === 2) {
      setSelectedItems(selectedCampaign.ads);
    }
  }, [selectedAdLevel]);

  //set the selectedCampaign based on the selectedAdLevel
  useEffect(() => {
    if (selectedAdLevel === 0) {
      setSelectedCampaign((prev) => ({
        ...prev,
        campaigns: selectedItems,
      }));
    } else if (selectedAdLevel === 1) {
      setSelectedCampaign((prev) => ({
        ...prev,
        adSets: selectedItems,
      }));
    } else if (selectedAdLevel === 2) {
      setSelectedCampaign((prev) => ({
        ...prev,
        ads: selectedItems,
      }));
    }
  }, [selectedItems]);

  useEffect(() => {
    const fetchCampaignsEffect = async () => {
      setCampaignsState({ ...defaultFetchState, loading: true });

      setStatus(true);

      const selectedLevelLabel = ["campaigns", "adsets", "ads"][
        selectedAdLevel
      ];

      const campaignsFilterIds = selectedCampaign["campaigns"].map(
        (item) => item.id
      );
      const adSetsFilterIds = selectedCampaign["adSets"].map((item) => item.id);
      const adsFilterIds = selectedCampaign["ads"].map((item) => item.id);
      const [insightResponse, orderResponse] = await fetchCampaignsService({
        adAccountId: selectedAdAccount.id,
        selectedLevelLabel: selectedLevelLabel,
        since: formatDateToYYYYMMDD(selectedRange[0].startDate),
        until: formatDateToYYYYMMDD(selectedRange[0].endDate),
        campaignsFilterIds: campaignsFilterIds,
        adSetsFilterIds: adSetsFilterIds,
        adsFilterIds: adsFilterIds,
        searchTerm: filters?.searchTerm,
      });
      setCampaignsState(insightResponse);
      setOrdersState(orderResponse);

      if (
        insightResponse.error?.response?.status === 500 ||
        orderResponse.error?.response?.status === 500
      ) {
        // setStatus(false);
      }
    };

    if (selectedAdAccount?.id) {
      fetchCampaignsEffect();
    }
  }, [selectedAdAccount, selectedAdLevel, selectedRange, filters]);

  useEffect(() => {
    fetchAdAccountsEffect();
  }, []);

  useEffect(() => {
    setSelectedCampaign({ campaigns: [], adSets: [], ads: [] });
    setSelectedAdLevel(0);
  }, [selectedAdAccount]);

  return (
    <campaignsContext.Provider
      value={{
        selectedItems,
        setSelectedItems,
        handleSelectAll,
        selectItem,
        selectable,
        selectedItem,
        setSelectedItem,
        actions,
        setCampaignData,
        campaignData,
        onSwitch,
        selectedAdLevel,
        setSelectedAdLevel,
        selectedCampaign,
        setSelectedCampaign,
        fetchAdAccountsEffect,
        adAccountsState,
        selectedAdAccount,
        campaignsState,
        ordersState,
        setSelectedAdAccount,
        selectedRange,
        setSelectedRange,
        setFilters
      }}
    >
      {children}
    </campaignsContext.Provider>
  );
};

export const useCampaignsContext = () => {
  return useContext(campaignsContext);
};
