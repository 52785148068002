import React, { useState, useEffect } from "react";
import Sidebar, { SidebarItem } from "./SidebarItem";
import { useAppContext } from "../context/Context";

const linkArr = [
  {
    id: 1,
    text: "Home",
    icon: "LayoutDashboard",
    path: "/",
  },
  {
    id: 2,
    text: "Ads Manager",
    icon: "Megaphone",
    path: "/campaigns",
  },
  {
    id: 3,
    text: "Products",
    icon: "Package",
    path: "/products",
  },
  {
    id: 4,
    text: "Ad Accounts",
    icon: "Facebook",
    path: "/ad",
  },
  {
    id: 5,
    text: "Publishers",
    icon: "Users",
    path: "/publishers",
    locked: true,
  },
  {
    id: 6,
    text: "Invitations",
    icon: "Send",
    path: "/invitations",
    locked: true,
  },
  {
    id: 7,
    text: "Admin Panel",
    icon: "PanelsTopLeft",
    path: "/adminpanel",
  },
];

const BigSidebar = ({ expanded, setExpanded }) => {
  const { userData } = useAppContext();
  const [tabName, setTabName] = useState("Home");

  const linkArr = [
    {
      id: 1,
      text: "Home",
      icon: "LayoutDashboard",
      path: "/",
    },
    {
      id: 2,
      text: "Ads Manager",
      icon: "Megaphone",
      path: "/campaigns",
    },
    {
      id: 3,
      text: "Products",
      icon: "Package",
      path: "/products",
    },
    {
      id: 4,
      text: "Ad Accounts",
      icon: "Facebook",
      path: "/ad",
    },
    {
      id: 5,
      text: "Publishers",
      icon: "Users",
      path: "/publishers",
      locked: true,
    },
    {
      id: 6,
      text: "Invitations",
      icon: "Send",
      path: "/invitations",
      locked: true,
    },
    {
      id: 7,
      text: "Admin Panel",
      icon: "PanelsTopLeft",
      path: "/adminpanel",
      visibility: userData.superadmin,
    },
  ];

  useEffect(() => {
    const path = window.location.pathname;
    const activeTab = linkArr.find((link) => path === link.path);
    setTabName(activeTab?.text || "Home");
  }, []);

  return (
    <main className="bg-white">
      <Sidebar expanded={expanded} setExpanded={setExpanded}>
        {
        linkArr.filter((link) => !link.hasOwnProperty("visibility") || link.visibility)
        .map((link) => {
          return (
            <SidebarItem
              role={userData.role}
              icon={link.icon}
              text={link.text}
              path={link.path}
              key={link.id}
              isLocked={link.locked}
              tabName={tabName}
              setTabName={setTabName}
            />
          );
        })}
      </Sidebar>
    </main>
  );
};

export default BigSidebar;
