import React, { useState, useEffect } from "react";
import { useAppContext } from "../context/Context";
import { TableContextProvider, useTableContext } from "../context/TableContext";
import { storeProductsConfiguration as tableConfiguration } from "../constant/tablesParam";
import { defaultFetchState, getProductsByStoreId } from "../utils/services";
import SyncProductsTable from "./table/SyncProductsTable";
import API from "../utils/api";
import Input from "./Input";
import Button from "./Button";
import Icon from "./Icon";
import { validShopifyDomain } from "../utils/helper";
import { useErrorContext } from "../context/ErrorContext";
import { BiLogoShopify } from "react-icons/bi";


const StoreModal = ({ showModal }) => {
  const { setShowModal } = useAppContext();
  const [productsState, setProductsState] = useState({...defaultFetchState, data:null});
  const [storeUrl, setStoreUrl] = useState("");

  const toggeleModal = () => {
    setShowModal(false);
    setProductsState({...defaultFetchState, data:null});
    setStoreUrl(null);
  };

  return (
    <>
      {!productsState?.data ? (
        <AddStore
          {...{ setShowModal, showModal, storeUrl, setStoreUrl,productsState, setProductsState }}
        />
      ) : (
        <TableContextProvider
          {...{
            selectable: true,
          }}
        >
          <Products {...{ showModal, toggeleModal, storeUrl,productsState, setProductsState }} />
        </TableContextProvider>
      )}
    </>
  );
};

const AddStore = ({
  setShowModal,
  showModal,
  setProductsState,
  productsState,
  storeUrl,
  setStoreUrl,
}) => {
  const [loading, setLoading] = useState(false);
  const [storeId, setStoreId] = useState(null);
  const [installState, setInstallState] = useState(null);
  // const [checkLoading, setCheckLoading] = useState(false);
    const {setStatus} = useErrorContext()


  const goToInstall = () => {
    let link = "/admin/oauth/redirect_from_cli?client_id=4bc66ec32c62b5a063f2b7b8543ec9ce"
    if (!storeUrl.startsWith("https://")) {
      link = "https://" + storeUrl + link
    }else{
      link = storeUrl + link
    }

    setStoreUrl(storeUrl.replace("https://", ""));
    window.open( link , "_blank")
  }

  const handleToggel = () => {
    setShowModal(false);
    setStoreUrl("");
    setLoading(false);
    setStoreId(null);
  };

  const handleAddStore = async () => {
    setLoading(true);
    if (!isValid()) return;
    try {
      setStatus(true)
      const { data: newStore } = await API.post(`/store`, {
        shop: storeUrl,
      });
      
      if(!newStore.accessToken){
        setStoreId(newStore._id);
        goToInstall()
      }else{
        setInstallState("INSTALLED");
        setStoreId(newStore._id);
      }
    } catch (e) {
      setStoreId(null);
      console.log("ERROR!");
      console.log(e);
      if(e.response.status === 500) {
        setStatus(false)
      }
    }
    setLoading(false);
  };

  const handleCheck = async () => {
    // setCheckLoading(true);
    if (!isValid()) return;
    try {
      setStatus(true)
      const { data: storeData } = await API.get(`/store`,{
        params: {
          shop: storeUrl
        }
      });

      setStoreId(storeData._id);
      if (storeData.accessToken) {
        setInstallState("INSTALLED");
      } else {
        setInstallState("PENDING");
      }
    } catch (e) {
      setStoreId(false);
      console.log("ERROR!");
      console.log(e);
      if(e.response.status === 500) {
        setStatus(false)
      }
    }
    // setCheckLoading(false);
  };


  const handleSyncProducts = async () => {
    try {
      setProductsState({ ...productsState, loading: true });

      const { data, error } = await getProductsByStoreId(storeId) 
      if (error) {
        setProductsState({ error, loading: false, data: null });
        return 
      }
      setProductsState({ ...productsState, loading: false, data });
      setLoading(false);
    } catch (e) {
      console.log("ERROR!");
      console.log(e);
      setProductsState({ error:e, loading: false, data: null });
     
    }
  };

  const isValid = () => {
    if (!validShopifyDomain(storeUrl)) return false;

    return true;
  };

  useEffect(() => {
    if(installState === "INSTALLED"){
      handleSyncProducts()
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installState])

  const openTuto = () => {
    window.open("https://youtu.be/_uOJOQNOqrs", "_blank")
  }
  
  return (
    <div
      className={` ${
        showModal ? "fixed" : "hidden"
      } inset-0 bg-black bg-opacity-50 z-10 flex duration-500 justify-center items-center `}
    >
      <div className=" bg-[#F8F9FB] w-[500px] rounded-lg">
        <div className=" flex flex-col gap-4">
          <div>
            <div className="flex px-5 py-3 rounded justify-between items-center  bg-white">
              <h1 className="text-[#2b4d75] text-xl font-bold">
                Add new store
              </h1>
              <button onClick={handleToggel}>
                <Icon name="X" />
              </button>
            </div>
            <hr className="bg-[#CAD1DB]" />
          </div>
          <div className="flex px-5 py-3 flex-col gap-6">
            <div>
              <Input
                value={storeUrl}
                onChange={(e) => setStoreUrl(e.target.value.trim())}
                placeholder="example.myshopify.com"
                label="Shopify store URL"
                disabled={storeId}
                icon={BiLogoShopify }
              />
              <div onClick={openTuto} className="text-blue ml-2 mt-1 text-[12px] underline cursor-pointer">How to find my store url ?</div>
            </div>
            {storeId && (
              <Button value={"Check connection"} onClick={handleCheck} />
            )}
            {installState === "PENDING" && (
              <div className="font-medium  text-[#5A5C73] text-[14px]">
                Install failed, please try again <b className="font-bold  text-blue-500 cursor-pointer " onClick={goToInstall}>Install link</b>
              </div>
            )}
          </div>

          {!storeId && (
            <div className="w-full flex justify-end px-5 py-3 border border-[#CAD1DB] bg-white rounded-b">
              <Button
                value={"Add store"}
                onClick={handleAddStore}
                disabled={!isValid() || storeId}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Products = ({ showModal, toggeleModal, storeUrl,productsState, setProductsState }) => {
  const { setShowModal, fetchProductsEffect } = useAppContext();
  const { selectedItems } = useTableContext();
  const [isLoading, setIsLoading] = useState(false);
  const { setStatus} = useErrorContext()

  const handleProductsImport = async () => {
    try {
      setStatus(true)
      setIsLoading(true);
      const response = await API.post(`/addProducts`, {
        products: selectedItems,
        storeName: storeUrl,
      });
      console.log(response)
      setIsLoading(false);
      setShowModal(false);
      fetchProductsEffect();
    } catch (e) {
      setIsLoading(false);
      console.log("Error!!");
      if(e.response.status === 500) {
        setStatus(false)
      }
    }
  };

  return (
    <div
      className={` ${
        showModal ? "fixed" : "hidden"
      } inset-0 bg-black bg-opacity-50 z-10 flex duration-500 justify-center items-center `}
    >
      <div className=" bg-[#F8F9FB] w-[1000px] rounded-lg">
        <div className=" flex flex-col gap-4">
          <div>
            <div className="flex px-5 py-3 justify-between items-center rounded bg-white">
              <h1 className="text-[#2b4d75] text-xl font-bold">
                Import products
              </h1>
              <button
                onClick={toggeleModal}
                className="hover:rotate-180 origin-center transition"
              >
                <Icon name="X" />
              </button>
            </div>
            <hr className="bg-[#CAD1DB]" />
          </div>

          <div className="flex px-5 py-3 flex-col gap-6">
            <SyncProductsTable {...{productsState, setProductsState}} />
          </div>
          <div className="w-full flex justify-end px-5 py-3 border border-[#CAD1DB] bg-white rounded-b">
            <Button
              value={"Import"}
              onClick={() => handleProductsImport()}
              disabled={selectedItems.length > 0 ? false : true}
              icon={{
                name: "DownloadCloud",
              }}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreModal;
