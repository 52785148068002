import React, { useState } from "react";
import { Button } from "flowbite-react/components/Button";
import { HiOutlineViewColumns } from "react-icons/hi2";
import { BiCaretDown } from "react-icons/bi";
import FlowPopover from "../flowbite/Popover";
import FlowCheckbox from "../flowbite/Checkbox";
const DashboardFilter = ({
  columnFilters, 
  setColumnFilters,
  columnMetrics, 
  setMolumnMetrics
}) => {

  const [_filters, setFilters] = useState(columnFilters);
  const handleChange = (key, value) => {
    const _cols = { ..._filters };
    _cols[key].value = value;
    setColumnFilters(_cols);
    setFilters(_cols);
  }

  const handleArrayChange = (value, index) => {
    const _metrics = [...columnMetrics];
    _metrics[index].value = value;
    setMolumnMetrics(_metrics);
  }

  return (
    <div className="flex justify-end m-2">
      <FlowPopover
        aria-labelledby="area-popover" 
        arrow={false}
        content={
          <div className="flex w-64 flex-col gap-4 p-4 text-sm text-gray-500 dark:text-gray-400"> 
            <div>Columns</div>
            {Object.keys(_filters).map((key, index) => (
              <FlowCheckbox
                key={columnFilters[key].code}
                label={columnFilters[key].name}
                id={columnFilters[key].code}
                value={columnFilters[key].value}
                onChange={(value) => handleChange(key,value)}
              />
            ))}
             <div>Metrics</div>
             {columnMetrics.map((metric, index) => (
              <FlowCheckbox
                key={metric.code}
                label={metric.name}
                id={metric.code}
                value={metric.value}
                onChange={(value) => handleArrayChange(value, index)}
              />
            ))}
          </div>
        }
        button={
          <Button color="gray">
            <div className="flex gap-2 items-center">
            <HiOutlineViewColumns />
            Columns
            <BiCaretDown className="ml-2" /></div>
          </Button>
        }
      />
    </div>
  );
};
export default DashboardFilter;
