import React, { useState } from "react";
import Modal from "../Modal";
import Input from "../Input";
import api from "../../utils/api";
import { useErrorContext } from "../../context/ErrorContext";

const PublisherModal = ({ setShowPoblisherModal, showPoblisherModal }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
    const { setStatus} = useErrorContext()

  const handleInvite = async () => {
    try {
      setStatus(true)
      const res = await api.post("/invite-user", { email: email });
      console.log(res.data);
      setShowPoblisherModal(false);
      setError("");
    } catch (error) {
      console.log(error);
      setError(error?.response?.data?.message);
      if(error.response.status === 500) {
        setStatus(false)
      }
    }
  };
  const isValid = () => {
    if (email) {
      return true;
    } else {
      return false;
    }
  };

  const onClose = () => {
    setError("");
    setEmail("");
  };

  return (
    <Modal
      showModal={showPoblisherModal}
      setShowModal={setShowPoblisherModal}
      title={"Invite Publishers"}
      actions={[
        {
          value: "Send",
          onClick: handleInvite,
          disabled: !isValid(),
          icon: { name: "Send" },
        },
      ]}
      onClose={onClose}
    >
      <div className="w-[400px] flex flex-col gap-5">
        <div className="flex flex-col gap-4">
          <Input
            value={email}
            label="Email"
            placeholder="example@gmail.com"
            onChange={(e) => setEmail(e.target.value)}
            error={error}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PublisherModal;
