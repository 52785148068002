import { Label } from "flowbite-react/components/Label";
import { Checkbox } from "flowbite-react/components/Checkbox";

const customTheme = {
  root: {
    base: "h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-2 dark:border-gray-600 dark:bg-gray-700 cursor-pointer",
    color: {
      blue: "text-blue-700 focus:ring-none dark:ring-offset-blue-700 dark:focus:ring-blue-700",
    },
  },
};

const FlowCheckbox = ({
  label,
  id,
  value = false,
  indicator = "",
  disabled = false,
  onChange,
}) => {
  return (
    <div className="flex max-w-md flex-col gap-4" >
      <div className="flex items-center gap-2">
        <Checkbox
          theme={customTheme}
          id={id}
          checked={value}
          disabled={disabled}
          color={"blue"}
          onChange={(e) => onChange(e.target.checked)}
        />
        <div className="flex flex-col">
          {label && (
            <Label htmlFor="accept" className="flex">
              {label}
            </Label>
          )}
          {indicator && (
            <div className="text-gray-500 dark:text-gray-300">
              <span className="text-xs font-normal">{indicator}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlowCheckbox;
