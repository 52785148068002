import React from "react";
import { array, func } from "prop-types";
import { Label } from "flowbite-react/components/Label";
import { Spinner } from "flowbite-react/components/Spinner";
import { FiUploadCloud } from "react-icons/fi";

const Banner = ({ onClick, onDrop }) => {
  const handleDragOver = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDrop(ev.dataTransfer.files);
  };
  return (
    <div
      className="border-2 border-dashed bg-blue-100 border-gray-500 rounded p-4 text-center cursor-pointer h-40 flex flex-col justify-center gap-2 text-black-100"
      onClick={onClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <span>Click to Add files</span>
      <span>Or</span>
      <span>Drag and Drop files here</span>
    </div>
  );
};

const DropZone = ({ onChange, accept = ["*"], loading }) => {
  const inputRef = React.useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleChange = (ev) => {
    onChange(ev.target.files);
  };

  const handleDrop = (files) => {
    onChange(files);
  };

  return (
    <div className="flex w-full items-center justify-center">
      <Label
        htmlFor="dropzone-x"
        className="flex h-40 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div className="flex flex-col items-center justify-center pb-6 pt-5">
          <div className="mb-4 h-8 w-8 " >
            {  loading ?  <Spinner /> :  <FiUploadCloud size={32} color="" /> }
          </div>
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            VIDEO, MP4 or MOV
          </p>
        </div>
        <input
          id="dropzone-x"
          type="file"
          arialabel="add files"
          className="w-full h-full hidden"
          ref={inputRef}
          multiple="multiple"
          onChange={handleChange}
          accept="video/mp4,video/x-m4v,video/*"
        />
      </Label>
    </div>
  );
};

DropZone.propTypes = {
  accept: array,
  onChange: func,
};

export { DropZone };
