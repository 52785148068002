import React from "react";
import InputForm from "../components/InputForm";
import HeroImg from "../components/HeroImg";

const SignUp = () => {
  return (
    <div className="h-screen flex">
      <InputForm />
      <HeroImg />
    </div>
  );
};

export default SignUp;
