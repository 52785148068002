import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import api from "../utils/api";
import { useErrorContext } from "../context/ErrorContext";
const Invitation = () => {
    const { setStatus} = useErrorContext()

  const [invitedData, setInvitedData] = useState({
    ownerName: "",
    inviteEmail: "",
    status: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchInvitedUsers = async () => {
    if (id) {
      try {
        setStatus(true)
        const res = await api.get(`/get-invited-user/${id}`);
        if (res.data.data.status === "accepted") {
          navigate("/login");
        }
        console.log(res.data);
        setInvitedData({
          ownerName: res.data.data.ownerId.fullname,
          inviteEmail: res.data.data.email,
          status: res.data.data.status,
        });
      } catch (error) {
        console.log(error);
        if(error.response.status === 500) {
        setStatus(false)
      }
      }
    }
  };
  useEffect(() => {
    fetchInvitedUsers();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAccept = () => {
    navigate("/signup/"+id)
  }
  return (
    <div className="h-screen flex bg-[#EDF5FF] items-center justify-center">
      <div className="flex-1 items-center  flex justify-center">
        <div className="w-[700px]">
          <img src="/images/collab.png" alt="collab"/>
        </div>
      </div>
      <div className="flex-1 items-center flex-col  flex justify-center">
        <div className="w-[620px] bg-[#F8F9FB] flex-col  rounded-lg shadow-xl p-10 leading-7  gap-12 flex ">
          <div className="text-[30px] font-medium text-center text-[#5A5C73]  leading-[52px]">
            Welcome to DEMO
          </div>
          <div className="font-normal text-[16px]  text-[#5A5C73]  ">
            <div>
              <b>{invitedData?.ownerName}</b> have invited you to join his team.{" "}
            </div>
            <div>
              You will be able to participate in his organazation as{" "}
              <b>Publisher</b>.{" "}
            </div>
            <div>
              To accept the invitation, please Signin with{" "}
              <b>{invitedData.inviteEmail}</b>{" "}
            </div>
          </div>
          <div className="flex justify-center flex-col">
            <Button value="Accept" icon={{ name: "MailCheck" }} onClick={handleAccept}/>
            <div className="font-small  text-[#5A5C73] text-[13px]">
              If you dont know Ayoub and think this is a mistake, please contact
              us at <b>support@demo.com</b>
            </div>
          </div>
        </div>
        <div className="font-small  text-[#5A5C73] text-[13px]">
          DevShop SUITE - All rights reserved
        </div>
      </div>
    </div>
  );
};

export default Invitation;
