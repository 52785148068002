export const validShopifyDomain = (shop) => {
  return /^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]*\.myshopify\.com$/.test(shop);
};


export const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const handleLogin = (loginCallback) => {
  window.FB.login(
    function (response) {
      console.log(response);
      if (response) {
        loginCallback(response);
      }
    },
    {
      // config_id: "275441358750788",
      config_id: "1604457580092992",
    }
  );
};


export const moneyFormat = (money, currency="") => {
  return currency  + (parseInt(money, 10)/100).toFixed(2)
}

export const validateDomainUrl = (url) => {
  const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9][a-zA-Z0-9-]*\.){0,}([a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z]{2,})(\/.*)?$/;
  return domainRegex.test(url);
};

export const  appendFormData = (formData, data, parentKey = '') => {
  if (data && typeof data === 'object' && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      appendFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    formData.append(parentKey, data);
  }

}


export const formatDateToYYYYMMDD = (date)  => {
  const year = date.getFullYear();
  // Adding 1 to make month 1-indexed. PadStart to ensure two digits.
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const formatDateToDDMMYYYY = (date) => {
  const day = date.getDate().toString();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
}