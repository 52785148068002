import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import BigSidebar from "../../components/BigSidebar";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppContext } from "../../context/Context";
import StoreModal from "../../components/StoreModalV2";
import { useErrorContext } from "../../context/ErrorContext";
import { useMediaQuery } from 'react-responsive'
const SharedLayout = () => {
  const { sidebar, userData, showModal } = useAppContext();
  const { pathname } = useLocation();
  const { status} = useErrorContext()
  
  const isDesktop  = useMediaQuery({ minWidth: 768 })
  const isMobile  = useMediaQuery({ maxWidth: 767 })

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [expanded, setExpanded] = useState(isDesktop);

  if (!userData) {
    return <Navigate to={"/login"} />;
  }

  if(!status) {
    return <Navigate to={"/error"} />;
  }

  return (
    <div className="bg-[#F8F9FB]">
      {
        <StoreModal showModal={showModal} />
      }
      <div className="flex relative">
        <BigSidebar
          expanded={expanded}
          setExpanded={setExpanded}
          sidebar={sidebar}
        />
        { isMobile && expanded &&
          <div className="bg-black opacity-30 absolute right-0 bottom-0 left-0 top-0 z-1" onClick={() => setExpanded(false)}>
            </div>
        }
        <div className={`${expanded && isDesktop ? "ml-[210px] " : "ml-[70px]"} flex-[7] overflow-y-scroll`}>
          <Outlet context={[expanded]} />
        </div>
      </div>
    </div>
  );
};

export default SharedLayout;
