import React from "react";
import { useNavigate } from "react-router";
import { useErrorContext } from "../context/ErrorContext";
export const ErrorPage = () => {
    const navigate = useNavigate();
      const {setStatus} = useErrorContext()

    const handleRedirectHome = () => {
      setStatus(true)
      navigate('/');
};;
  return (
    <div className="error-page flex flex-col justify-center" >
      <div>
        <img src="/images/error.png" alt="error" className="mx-auto w-[35%]"/>
      </div>
      <div className="flex justify-center flex-col text-center">
        <h1 className="font-bold text-[30px] text-[#303030]">Whoops, something went wrong</h1>
        <p>Please try after somethime</p>
        <button className="home-btn bg-[#247AFB] w-fit mx-auto p-2 px-3 rounded-xl text-white mt-3" onClick={() => handleRedirectHome()}>
          Go to homnepage
        </button>
      </div>
    </div>
  );
};