import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const clientId = process.env.REACT_APP_MODE === "SANDBOX" ? process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT : process.env.REACT_APP_PAYPAL_CLIENT;

const PayPalSubscriptionBtn = ({ planId, handleApprove = () => { }, handleCancel = () => { }, handleError = () => { } }) => {

    return (
        <PayPalScriptProvider options={{ "client-id": clientId, "vault": true, "intent": "subscription" }}>
            <PayPalButtons
                style={{
                    shape: 'rect',
                    color: 'gold',
                    layout: 'vertical',
                    label: 'subscribe'
                }}
                createSubscription={(data, actions) => {
                    return actions.subscription.create({
                        plan_id: planId
                    });
                }}
                onApprove={handleApprove}
                onCancel={handleCancel}
                onError={handleError}
            />
        </PayPalScriptProvider>
    );
};

export default PayPalSubscriptionBtn;
