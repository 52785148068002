import React, { useEffect, useState } from "react";
import Card from "../components/Card";
import Icon from "../components/Icon";
import { useNavigate } from "react-router-dom";
import { getUserOnboarding } from "../utils/services";
import { Card as FlowCard } from "flowbite-react/components/Card";
import FlowButton from "./flowbite/Button";
import { FaMeta } from "react-icons/fa6";
import { FaShopify } from "react-icons/fa";
import { LuPackagePlus } from "react-icons/lu";
import { PiMegaphoneFill } from "react-icons/pi";
import { useAppContext } from "../context/Context";
import { TbCloudCode } from "react-icons/tb";
import { CgPlayButtonO } from "react-icons/cg";

const Dashboard = () => {
  const navigate = useNavigate();
  const { localStorageEffect, getLocalStorage } = useAppContext();
  const [showMessage, setShowMessage] = useState(!getLocalStorage("welcome_message"));
  const [selectedStep, setSelectedTab] = useState(1);
  const [onboardingState, setOnboardingState] = useState({});
  const [onboardingSteps, setOnboardingSteps] = useState([
    { id: 1, name: "Connect Facebook", status: "pending" },
    { id: 2, name: "Connect Shopify", status: "pending" },
    { id: 3, name: "Import products & costs", status: "pending" },
    { id: 4, name: "Create your first campaign", status: "pending" },
    { id: 5, name: "Track your first AdPixel purchase", status: "pending" },
  ]);

  const content = {
    1: {
      title: "Link your first FB Account",
      description:
        "Connect a Facebook profile with admin access to your ad account to start.",
      icon: <FaMeta color="#0072ff" size="70" />,
      link: () => navigate("/ad"),
    },
    2: {
      title: "Link your first Store",
      description:
        "Connect a Shopify store and install AdPixel to track your sales & events.",
      icon: <FaShopify color="#96c045" size="70" />,
      link: () => navigate("/products"),
    },
    3: {
      title: "Syncronize your products",
      description:
        "Import your products and configure their costs to start creating campaigns for a better view of the profit.",
      icon: <LuPackagePlus color="#ff822d" size="70" />,
      link: () => navigate("/products"),
    },
    4: {
      title: "Launch your first campaign",
      description: "Easy steps to create a powerful campaign.",
      icon: <PiMegaphoneFill color="#697586" size="70" />,
      link: () => navigate("/campaigns"),
    },
    5: {
      title: "Fire first AdPixel",
      description:
        "Get your first sale and fire AdPixel to track your profits.",
      icon: <TbCloudCode color="#202939" size="70" />,
    },
  };

  const handleToggle = async () => {
    setShowMessage(false);
    await localStorageEffect({ welcome_message: true });
  };

  const handleYoutube = async () => {
    window.open("https://youtu.be/-zM0qCcBCZI", "_blank")
  }

  useEffect(() => {
    getUserOnboarding().then((response) => {
      setOnboardingState(response);
    });
  }, []);

  useEffect(() => {
    if (onboardingState?.data) {
      const { product, adAccount, shopifySession, campaign, order } =
        onboardingState.data;
      setOnboardingSteps([
        {
          id: 1,
          name: "Connect Facebook",
          status: adAccount ? "done" : "pending",
        },
        {
          id: 2,
          name: "Connect Shopify",
          status: shopifySession ? "done" : "pending",
        },
        {
          id: 3,
          name: "Import products & costs",
          status: product ? "done" : "pending",
        },
        {
          id: 4,
          name: "Create your first campaign",
          status: campaign ? "done" : "pending",
        },
        {
          id: 5,
          name: "Track your first AdPixel purchase",
          status: order ? "done" : "pending",
        },
      ]);
    }
  }, [onboardingState]);
  return (
    <div className="flex flex-col gap-4">
      {showMessage  && (
        <div className=" bg-[url('https://monsterocu-dev-publicfiles.s3.eu-west-2.amazonaws.com/media/wavy-bg.png')] bg-[#f8fafc] rounded shadow p-4 gap-4 flex flex-col bg-no-repeat bg-[50%_100%] bg-[length:1460px]  relative">
          <div className="flex items-center justify-between">
            <div className=" text-[18px]  font-medium ">
              Welcome to AdClair 🎉
            </div>
            <button
              onClick={handleToggle}
              className="hover:rotate-180 origin-center transition"
            >
              <Icon name="X" />
            </button>
          </div>

          <div className="text-[16px]">
            <div className="flex gap-4 items-end">
              <Icon name="Quote" size="32" color="#202939" />
              <div>
                We’re thrilled you've chosen AdClair to help you with your
                online advertising journey.{" "}
              </div>
            </div>
            With our automated platform, launching ads is not only quick and
            effortless but also ensures continuous optimization to get the best
            results possible for your business.
            <div className="flex justify-end">
              <Icon name="Quote" size="32" color="#202939" />
            </div>
          </div>

          <div className="font-bold  flex justify-end italic ">
            AdClair Team
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex-1">
          <Card title={"Quick Onboarding ⚡"}>
            <div className="font-normal text-[14px] text-black-100">
              Let’s proceed with your onboarding. Follow these steps below to
              get started.
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col flex-1 gap-2">
                {onboardingSteps.map((step) => (
                  <Step
                    key={step.id}
                    {...step}
                    setSelectedTab={setSelectedTab}
                    isSelected={selectedStep === step.id}
                    selectedStep={selectedStep}
                    content={content}
                  />
                ))}
              </div>
            </div>
          </Card>
        </div>
        <div className="flex-1">
          <div className="shadow rounded-b bg-white ">
            <div className="gradien-light flex flex-col  p-4 h-[200px] relative  justify-center cursor-pointer" onClick={handleYoutube}>
              <div className="flex flex-col opacity-100 text-[#0a2540] px-6">
                <div className="font-semibold">Learn how to:</div>
                <div className="font-bold text-xl">Convert data to decisions</div>
              </div>
              <div className="absolute bottom-0 right-0 h-10 px-4  text-black bg-white rounded-l-t flex items-center justify-center">
                <img className="h-8" src="images/logo-black-long.png" />
              </div>
              <div className="absolute top-1/2 right-1/2  translate-x-1/2 -translate-y-1/2" >
                <CgPlayButtonO  size={75}  />
              </div>
            </div>
            <div className="p-4 flex flex-col gap-2"> 
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Learn how to convert data to decisions with AdClair
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                Gain full visibility & Rapidly Scale Your Ecommerce Store Revenue.
                Combine the entire customer journey of your ecommerce store by
                analyzing marketing, sales and product analytics all in one place.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step = ({
  id,
  name,
  status,
  setSelectedTab,
  isSelected,
  content,
  selectedStep,
}) => {
  const [show, setShow] = useState(id === selectedStep);
  useEffect(() => {
    setShow(id === selectedStep);
  }, [selectedStep]);

  return (
    <div
      onClick={() => setSelectedTab(id)}
      className={`flex flex-col rounded hover:bg-blue-100 cursor-pointer   transition ${
        show && "bg-blue-100 shadow-sm"
      }`}
    >
      <div
        className={`flex items-center gap-2 hover:bg-blue-100 cursor-pointer py-1 px-2 rounded transition`}
      >
        <div>
          {status === "done" ? (
            <div className="rounded-full bg-green p-1">
              <Icon name="Check" color="white" size="14" />
            </div>
          ) : (
            <Icon name="CircleDashed" size={20} color="#FFA800" />
          )}
        </div>
        <div
          className={`flex-1 text-bold ${
            status === "done" ? "text-black-100 line-through" : "text-black"
          } text-[14px]`}
        >
          {name}
        </div>
        <div className={`rounded-full  p-1 `}>
          <Icon
            name={!show ? "ChevronUp" : "ChevronDown"}
            color="#697586"
            size="16"
          />
        </div>
      </div>
      <Content {...content[id]} show={show} />
    </div>
  );
};

const Content = ({ title, description, image, link, show, icon = "" }) => {  
  const handleYoutube = async () => {
    window.open("https://youtu.be/-zM0qCcBCZI", "_blank")
  }

  return (
    <div
      className={`flex flex-col gap-2    relative overflow-hidden  transition ${
        show ? " px-2 py-2" : "h-[0px] p-0"
      }`}
    >
      {/* {image && (
        <div className="absolute left-0 bottom-0 w-40 blur -translate-x-1/3 translate-y-1/3 ">
          <img src={image} alt="logo" />
        </div>
      )} */}
      <div className="h-full flex justify-between items-center mx-[28px]">
        <div className="w-2/3 flex flex-col justify-between gap-4 ">
          <div className="text-[12px]  ">
            {description}
            <span className="text-blue hover:underline ml-1" onClick={handleYoutube}>Learn more</span>
          </div>
          <div className="flex gap-2 items-center justify-start">
            {link && (
              <FlowButton value="Start" size="xs" color="blue" onClick={link} />
            )}
          </div>
        </div>
        {icon}
      </div>
    </div>
  );
};

export default Dashboard;
