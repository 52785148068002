import React from "react";
import Icon from "./Icon";
import Button from "./Button";

const Modal = ({
  children,
  showModal,
  setShowModal,
  actions,
  title,
  onClose,
}) => {
  return (
    <div
      className={` ${
        showModal ? "fixed" : "hidden"
      } inset-0 bg-black bg-opacity-50 z-10 flex duration-500 justify-center items-center `}
    >
      <div className=" bg-[#F8F9FB] rounded-lg">
        <div className=" flex flex-col gap-4">
          <div>
            <div className="flex px-5 py-3 rounded justify-between items-center  bg-white">
              <h1 className="text-[#2b4d75] text-xl font-bold">{title}</h1>
              <button
                onClick={() => {
                  onClose();
                  setShowModal(false);
                }}
              >
                <Icon name="X" />
              </button>
            </div>
            <hr className="bg-[#CAD1DB]" />
          </div>
          <div className="flex px-5 py-3 flex-col gap-6">
            {children}
          </div>
          {actions?.length ? (
            <div className="w-full flex items-center justify-end px-5 py-3 border border-[#CAD1DB] bg-white rounded-b">
              {actions.map((action, i) => {
                return action.element  || <Button key={i} {...action} />;
              })}
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default Modal;
