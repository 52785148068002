import Icon from "../Icon";

export const statusBodyTemplate = (status = "") => {

  let color = "#CAD1DB";
  const test = false;
  if (["ready", "active", "accepted"].indexOf(status.toLowerCase()) !== -1) {
    color = "#34B86D";
  } else if (["pending", "warning", "inactive"].indexOf(status.toLowerCase()) !== -1) {
    color = "#FFA800";
  } else if (["blocked", "error", "removed"].indexOf(status.toLowerCase()) !== -1) {
    color = "#F5432C";
  }

  
  return (
    <div className="flex items-center font-medium ">
      <div className={`h-2 w-2 rounded-lg bg-[${color}] mx-2`}></div>
      <span className="capitalize">{status}</span>
    </div>
  );
};

export const imagyTextBodyTemplate = (value, imageSrc) => {
  return (
    <div className="flex items-center gap-2 px-4">
      <div className="w-8 h-8 flex items-center justify-center">
        {imageSrc ?
          <img src={imageSrc} />
            :
          <Icon name="Image" color={"#CAD1DB"} size="36" />
        }
       
      </div>
      <span>{value}</span>
    </div>
  );
};


export const linkBodyTemplate = (text, link) => {
  const handleClick = () => {
    if (link) window.open(link, "_blank");
  };
  return (
      <div className="cursor-pointer underline underline-offset-1 flex items-center" onClick={handleClick}>
          { text }
        {link ? (
          <Icon name="ExternalLink" size="14" />
        ) : undefined}
      </div>
  );
};
