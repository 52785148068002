import React, { useState } from "react";
import { Popover } from "flowbite-react/components/Popover";

const FlowPopover = ({
  content,
  button,
  arrow
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover
    aria-labelledby="area-popover"
    open={open}
    onOpenChange={setOpen}
    content={content}
    arrow={arrow}
  >
    {button}
  </Popover>
  );
};
export default FlowPopover;
