import React, { useEffect, useState } from "react";
import Select from "react-select";
import Preview from "./Preview";
import { useTableContext } from "../../context/TableContext.jsx";
import { useAppContext } from "../../context/Context";
import Input from "../Input";
import Card from "../Card";
import Icon from "../Icon";
import Button from "../Button";
import api from "../../utils/api.js";
import { useErrorContext } from "../../context/ErrorContext.jsx";

const ProductPreview = () => {
  const { selectedItem, setSelectedItem } = useTableContext();
  const {
    userData: { role },
  } = useAppContext();
  const [productData, setProductData] = useState(selectedItem);
  const [variantsState, setVariantsState] = useState(selectedItem?.variants);
  const [selectedPublishers, setSelectedPublishers] = useState([]);
  const [allPublishers, setAllPublishers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {setStatus} = useErrorContext()

  const {
    _id,
    title,
    storeName,
    adminLink,
    // options = [],
    variants = [],
  } = productData || {};
  const handleClick = () => {
    window.open(adminLink, "_blank");
  };

  //  when variant doest have cost,  attack cost attribute with value of empty string

  const defaultCost = ""; // Default value for cost

  useEffect(() => {
    // Preprocess variants to ensure all have cost attribute
    const variantsWithCost = variants.map((variant) => ({
      ...variant,
      cost: variant.cost || defaultCost, // If cost is falsy, assign defaultCost
    }));
    setVariantsState(variantsWithCost);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData?.variants]);

  const fetchData = async () => {
    if (role === "admin") {
      setIsLoading(true);
      try {
        setStatus(true)
        const { data: publishers } = await api.get("/get-users");
        setAllPublishers(
          publishers.data.map(({ fullname, _id }) => ({
            label: fullname,
            value: _id,
          }))
        );
        setIsLoading(false);
      } catch (error) {
        console.log(error, "error dataaa");
        setIsLoading(false);
        if(error.response.status === 500) {
        setStatus(false)
      }
      }
    }

    try {
      setStatus(true)
      const { data: singleProduct } = await api.get(
        `/getSingleProduct/${selectedItem._id}`
      );
      setProductData(singleProduct.data);
      // setAllPublishers(publishers.data.map(({ fullname, _id }) => ({ label: fullname, value: _id })));
    } catch (error) {
      console.log(error, "error dataaa");
      if(error.response.status === 500) {
        setStatus(false)
      }
    }

    return;
  };

  useEffect(() => {
    if (selectedItem?._id) {
      fetchData();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  // For setting selectedPublishers from database
  useEffect(() => {
    setSelectedPublishers(
      productData?.publishers?.map(({ fullName, publisherId }) => ({
        label: fullName,
        value: publisherId,
      }))
    );
  }, [productData]);

  const handleChange = (id, newCost) => {
    const updatedVariants = variantsState.map((variant) =>
      variant.id === id ? { ...variant, cost: newCost } : variant
    );
    setVariantsState(updatedVariants);
  };

  //  converted array to get object which consist only ID and Cost
  const updatedArr = function (variantsState) {
    return variantsState?.map((obj) => {
      return {
        id: obj?.id,
        cost: obj?.cost,
      };
    });
  };

  //  converted array of object into array of string
  const publisherArr = function (arr) {
    return arr?.map((obj) => ({
      fullName: obj?.label,
      publisherId: obj.value,
    }));
  };

  //   update product function
  const updateProduct = async () => {
    // Payloads
    const payload = updatedArr(variantsState);
    const publisher = publisherArr(selectedPublishers);
    const productId = [_id]; //only single id for particular product
    try {
      setStatus(true)
      setIsLoading(true);
      const res = await api.patch(`/updateProducts`, {
        productIds: productId, 
        idsPrice: payload, // {id,cost}
        publishers: publisher, // {fullname, publisherId}
      });
      console.log(res?.data, "updated");
      setSelectedItem(res.data?.data);
      setSelectedPublishers(
        res?.data?.data?.publishers?.map(({ fullName, publisherId }) => ({
          label: fullName,
          value: publisherId,
        }))
      );
      setVariantsState(res?.data?.data?.variants);
      setSelectedItem(null);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if(error.response.status === 500) {
        setStatus(false)
      }
    }
  };

  return (
    <Preview
      showPreview={selectedItem}
      handleToggle={() => setSelectedItem(null)}
      title={isLoading ? "Loading..." : `Product - ${title}`}
    >
      <div className="flex flex-col max-h-[100%] overflow-auto gap-4 justify-between h-full">
        <div className="flex gap-4 flex-col">
          <Card title="Details" icon="ReceiptText">
            <Input label="Store" value={storeName} disabled={true} />
            <div
              className="flex items-center cursor-pointer underline underline-offset-1 font-small text-[#5A5C73] text-[13px]"
              onClick={handleClick}
            >
              <div className="px-1">Open in Shopify Admin </div>
              <Icon name="ExternalLink" size="14" />
            </div>
          </Card>
          {role === "admin" ? (
            <Card title={"Publishers"} icon="UserRoundCog">
              <Select
                isDisabled={true}
                isMulti
                className="basic-single"
                classNamePrefix="select"
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                name="publisher"
                placeholder="Select publishers of this product.."
                value={selectedPublishers}
                onChange={setSelectedPublishers}
                options={allPublishers || []}
              />
            </Card>
          ) : undefined}
          {variantsState?.length ? (
            <Card title="Costs" icon="BadgeDollarSign">
              <div className="flex flex-col  gap-2">
                <div className="grid grid-cols-2 max-h-[350px] variantBox overflow-auto  gap-4">
                  {variantsState.map(({ title, price, cost, id }) => {
                    return (
                      <React.Fragment key={id}>
                        <Input label={title} value={price} disabled />
                        <Input
                          label={"Cost"}
                          type="number"
                          value={cost}
                          onChange={(e) => handleChange(id, e.target.value)}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </Card>
          ) : undefined}
        </div>
        {role === "admin" ? (
          <div className="flex items-center mb-10 justify-end">
            <Button
              value="Cancel"
              type="secondary"
              onClick={() => setSelectedItem(null)}
            />
            <Button
              disabled={isLoading || role === "publisher"}
              value="Save"
              icon={{ name: "Save" }}
              onClick={updateProduct}
            />
          </div>
        ) : undefined}
      </div>
    </Preview>
  );
};

export default ProductPreview;
