import React, { createContext, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { removeToken, removeUser } from "../utils/localStorage";
import Icon from "./Icon";
import { useAppContext } from "../context/Context";

const SidebarContext = createContext();

const Sidebar = ({ children, expanded, setExpanded }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    removeToken();
    removeUser();
    navigate("/login");
  };
  const navigateHome = () => navigate("/");

  const goTo = (path) => navigate(path);
  return (
    <div className={`h-screen bg-white fixed z-[2]  border-r shadow-sm font-semibold transition	${expanded ? "w-[210px]":  "w-[70px]"}`}>
      <div
        className="absolute top-16 right-0 translate-x-1/2 -translate-y-1/2 bg-white p-1   rounded-full cursor-pointer shadow"
        onClick={() => setExpanded(!expanded)}
      >
        <Icon name={!expanded ? "ChevronLeft" : "ChevronRight"} size="11" />
      </div>
      <div className="flex items-center justify-center h-16 cursor-pointer py-4 " onClick={navigateHome}>
        <div className="h-8 flex gap-2 ">
          <img  src="images/logos/logo-black-short.jpeg" />
          <img src="images/logo-black-long.png" className={`${!expanded && "hidden"} `} />
        </div>
      </div>
      <nav
        className={`flex gap-[100px] h-[calc(100%-70px)]  flex-col py-2  border-t-[1px] ${
          expanded ? "w-52" : "w-16"
        }`}
      >
        <SidebarContext.Provider value={{ expanded, setExpanded }}>
          <ul
            className={`flex-1 flex 2 flex-col px-3 ${
              expanded ? "w-52" : "w-16"
            }`}
          >
            {children}
          </ul>
        </SidebarContext.Provider>
        <div>
          <div
            onClick={() => window.open("/privacy-policy", "_blank")}
            className=" cursor-pointer pl-6 flex p-3  "
          >
            <Icon name='Shield' color={"#697586"} />
            <div
              className={`
                flex justify-between items-center
                overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
            `}
            >
              <div className="leading-4">Privacy policy</div>
            </div>
          </div>
          <div
            onClick={() => handleLogout()}
            className=" cursor-pointer pl-6 flex p-3 "
          >
            <Icon name='LogOut' color={"#697586"} />
            <div
              className={`
                flex justify-between items-center
                overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
            `}
            >
              <div className="leading-4">Logout</div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;

export function SidebarItem({
  icon,
  text,
  path,
  active,
  tabName,
  setTabName,
  role,
  isLocked
}) {
  const navigate = useNavigate();
  const { expanded} = useContext(SidebarContext);
  const { userData } = useAppContext();
  const goTo = (path) => navigate(path);

  const handleClick = () => {
    if (isLocked) return;
    setTabName(text);
    goTo(path);
  }
  return (
    <>
      {userData.role === "publisher" &&
      ["Publishers", "Invitations"].indexOf(text) !== -1 ? (
        ""
      ) : (
        <div
          onClick={handleClick}
          className={`
          relative flex items-center py-3 px-3 my-1
          rounded-md cursor-pointer
          transition-colors group
          ${
            tabName === text
              ? "bg-blue-100 "
              : "hover:bg-blue-100 "
          }
      `}
        >
          <Icon name={isLocked ? "Lock" : icon} color={tabName === text ? "#0072ff" : "#697586"} size={30}/>
          {
            expanded && 
            <span
              className={`overflow-hidden transition-all text-[16px]  leading-5 ${
                expanded ? "w-52 ml-3" : "w-0"
              }`}
            >
              {text}
            </span>
          }

          {!expanded && (
            <div
              className={`
                absolute left-full rounded-md px-2 py-1 ml-6
                bg-[#ddebff]  text-sm
                invisible opacity-20 -translate-x-3 transition-all
                group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 
            `}
            >
              {text}
            </div>
          )}
        </div>
      )}
    </>
  );
}
