import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go";
import OAuth from "./OAuth";
import { HiMail } from "react-icons/hi";
import FlowInput from "../components/flowbite/Input";
import toast from 'react-hot-toast';
import api from "../utils/api";
import Button from "./Button";
import { useErrorContext } from "../context/ErrorContext";
import { isEmailValid } from "../utils/helper";
const InputForm = () => {
  const [eyeOpen, setEyeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { id } = useParams();
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    password: "",
    // termsAndConditions: false,
    isSocialLogIn: false,
  });
  const {setStatus} = useErrorContext()
  // const [boxChecked, setBoxChecked] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const isFormDataFilled = () => {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (formData[key] === "") {
          return false;
        }
      }
    }
    
    if (!isEmailValid(formData.email)) {
      return false
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setError("");
      setStatus(true)
      const res = await api.post(id ? "/sign-up-publisher" : "/sign-up", {
        fullname: formData.fullname,
        email: formData.email,
        password: formData.password,
        isSocialLogIn: formData.isSocialLogIn,
        // termsAndConditions: formData.termsAndConditions,
      });
      console.log(res)
      // const data = res?.data;
      setIsLoading(false);

      toast.success("Account created successfully. Please login to continue");


      navigate("/login?email=" + formData.email);
    } catch (error) {
      console.log(error?.message);
      setError(error?.response?.data?.message);
      setIsLoading(false);
      if(error.response.status === 500) {
        setStatus(false)
      }
    }
  };

  const getUserdata = async () => {
    if (id) {
      try {
        setStatus(true)
        const res = await api.get(`/get-invited-user/${id}`);

        setFormData({
          ...formData,
          email: res.data.data.email,
        });
        setEmailDisabled(true);
      } catch (error) {
        console.log(error);
        setError(error.response.data.message);
        setEmailDisabled(false);
        if(error.response.status === 500) {
        setStatus(false)
      }
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    getUserdata();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="w-full lg:w-[40%] h-full bg-[#fff] flex-col flex justify-center items-center  ">
        <img src="/images/logo-black-long.png" alt="logo" className="h-20 lg:hidden  " />
      <div className="w-[400px] bg-[#fff] rounded-[5px] flex gap-3  px-[40px] ">
        <div className=" flex flex-col gap-5">
          <div className="flex flex-col gap-5">
         
            <h1 className="text-[30px] font-medium text-center leading-[52px]">
            Create an account
            </h1>
            <p className="text-center text-[15px] leading-7 font-normal text-black-100 ">
            Quickly grow your business with AdClair's automated paid ads.{" "}
            </p>
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              
            <FlowInput
                label="Full name"
                type="text"
                id="fullname"
                value={formData.fullname}
                onChange={handleChange}
                disabled={isLoading}
                required
              />
              <FlowInput
                label="Email"
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                disabled={emailDisabled || isLoading}
                icon={HiMail}
                required
              />
                  
                  <FlowInput
                      label="Password"
                      type={eyeOpen ? "text" : "password"}
                      id="password"
                      value={formData.password}
                      onChange={handleChange}
                      disabled={isLoading}
                      addon={
                        <button
                          type="button"
                          onClick={() => setEyeOpen(!eyeOpen)}
                        >
                        {eyeOpen ? (
                          <GoEye className="text-2xl" />
                        ) : (
                          <GoEyeClosed className="text-2xl" />
                        )}
                      </button>
                      }
                    />
            </div>
            {/* <div className="flex gap-2 ">
              <input
                type="checkbox"
                className="w-5 h-5 flex items-center p-[2px]"
                id="termsAndConditions"
                onChange={handleChange}
                value={formData.termsAndConditions}
                required
              />
              <label htmlFor="termsAndConditions" className="text-[14px]">
                I agree to the terms and conditions and the privacy policy
              </label>
            </div> */}
            <div className="flex flex-col gap-3">
              <Button
                type="primary"
                value={"Sign Up"}
                loading={isLoading}
                disabled={!isFormDataFilled() || isLoading}
                className="w-full bg-[#0D6EFD] disabled:bg-gray-700 text-white text-center font-medium text-[16px] leading-6 h-14 rounded-lg"
              />

              <p className="text-red">{error}</p>
              {/* <p className="w-full text-center text-[16px] font-normal leading-4 text-[#5A5C73]">
                OR
              </p>
              <button className="w-full flex gap-2 justify-center items-center bg-[#EDF5FF]  text-center font-medium text-[16px] leading-6 h-14 rounded-lg">
                <img className="w-12" src="/images/logoGoogle.png" alt="" />{" "}
                <span>Sign Up with Google</span>
              </button> */}
            </div>
            {/* {id ? (
              ""
            ) : (
              <p className="w-full text-center text-[16px] font-normal leading-4 text-[#5A5C73]">
                OR
              </p>
            )} */}
            <div className="flex flex-col gap-6">
              <p className="w-full mt-10 text-center text-[16px] font-medium leading-6 text-[#5A5C73]">
                Have an account?{" "}
                <Link to={"/login"} className="text-[#0D6EFD]">
                  Sign In
                </Link>
              </p>
            </div>
          </form>
          {/* {id ? (
            ""
          ) : (
            <div className="flex flex-col gap-6">
              <OAuth />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default InputForm;
