import React from "react";

import { useAppContext } from "../context/Context";
import Page from "./Page";
import Dashboard from "../components/Dashboard";

const Home = () => {
  const { userData } = useAppContext();

  const { fullname } = userData;

  const actions = [];

  const getName = () => {
    if (fullname) return fullname.split(" ")[0];
    return "";
  };
  return <Page title={"Dashboard"} actions={actions} fullWidth={false}>
    <Dashboard/>
  </Page>;
};

export default Home;
